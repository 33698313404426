const HaProxy = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M34 20C34 27.732 27.732 34 20 34C12.268 34 6 27.732 6 20C6 12.268 12.268 6 20 6C27.732 6 34 12.268 34 20Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M19 17V15H21V17H19Z" stroke="currentColor" strokeWidth="2.8" />
    <path d="M15 21V19H17V21H15Z" stroke="currentColor" strokeWidth="2.8" />
    <path d="M23 21V19H25V21H23Z" stroke="currentColor" strokeWidth="2.8" />
    <path d="M19 25V23H21V25H19Z" stroke="currentColor" strokeWidth="2.8" />
    <path d="M16 28V27H17V28H16Z" stroke="currentColor" strokeWidth="2.8" />
    <path d="M23 28V27H24V28H23Z" stroke="currentColor" strokeWidth="2.8" />
    <path d="M27 24V23H28V24H27Z" stroke="currentColor" strokeWidth="2.8" />
    <path d="M27 17V16H28V17H27Z" stroke="currentColor" strokeWidth="2.8" />
    <path d="M23 13V12H24V13H23Z" stroke="currentColor" strokeWidth="2.8" />
    <path d="M16 13V12H17V13H16Z" stroke="currentColor" strokeWidth="2.8" />
    <path d="M12 17V16H13V17H12Z" stroke="currentColor" strokeWidth="2.8" />
    <path d="M12 24V23H13V24H12Z" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { HaProxy };
