const Termidesk = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M20 13V23M10.6484 27L29.4243 27M20 5L34 10.8333V29.1667L20 35L6 29.1667V10.8333L20 5ZM12 13H28V23H12V13Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Termidesk };
