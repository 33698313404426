const CloudsCheckmark = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="28"
      viewBox="0 0 36 28"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 10C26 5.58171 22.418 2 18 2C13.582 2 10 5.58171 10 10C5.58203 10 2 13.5817 2 18C2 22.4183 5.58203 26 10 26H26C30.418 26 34 22.4183 34 18C34 13.5817 30.418 10 26 10Z"
        stroke="#222222"
        strokeWidth="2.8"
      />
      <path
        d="M22.8 13.8011L17.2808 19.5738L13.1999 15.799"
        stroke="#222222"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { CloudsCheckmark };
