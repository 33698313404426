const Prometheus = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M12 25H20M24 19L22.0952 21L9.90476 21L8 19M20 13.2724C20 15.3311 18.2091 17 16 17C13.7909 17 12 15.3311 12 13.2724L16 7L20 13.2724ZM30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Prometheus };
