const Docker = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M31 21V22.0074C30.1772 25.1305 26.2805 31.4976 17.2761 31.9811C13.3269 32.2162 5.54686 30.3491 6.0207 21H31ZM31 21V16M31 21H36M15 15H9V21H15M15 15V21M15 15H21M15 15V9H21M15 21H21M21 15V21M21 15V9M21 15H27M21 21H27V15M21 9H27V15"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Docker };
