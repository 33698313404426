const VictoriaMetrics = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M6 15L20 26L34 15M6 22L20 33L34 22M8 9.45975L19.8033 19L32 9.45975C25.76 6.89773 16.64 5.53271 8 9.45975Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { VictoriaMetrics };
