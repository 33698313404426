const Clouds = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="30"
      viewBox="0 0 36 30"
      fill="none"
      className={className}
    >
      <path
        d="M15.4707 3.66634C16.7914 2.33776 18.569 1.52388 20.5241 1.52388C24.2673 1.52388 27.3597 4.50701 27.8406 8.37391C31.0485 8.88942 34.0001 12.0453 34.0001 15.8614C34.0001 17.6592 33.3444 19.3104 32.3156 20.6081"
        stroke="currentColor"
        strokeWidth="2.98667"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9519 20.9149C28.9519 17.0988 26.0003 13.943 22.7923 13.4275C22.3115 9.56056 19.2191 6.57743 15.4759 6.57743C11.7327 6.57743 8.64031 9.56057 8.15946 13.4275C4.95157 13.943 2 17.0988 2 20.9149C2 25.0909 5.53449 28.4761 9.07156 28.4761L9.08575 28.4761C9.09534 28.4761 9.10493 28.476 9.11451 28.4759H19.6585C20.3919 28.4759 21.1254 28.4733 21.859 28.4761C25.3999 28.4897 28.9519 25.0993 28.9519 20.9149Z"
        stroke="currentColor"
        strokeWidth="2.98667"
      />
    </svg>
  );
};

export { Clouds };
