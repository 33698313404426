const Ram = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="22"
      viewBox="0 0 30 22"
      fill="none"
      className={className}
    >
      <rect
        x="2"
        y="1.5"
        width="26"
        height="14"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M4 15.5V20.5H26V15.5" stroke="currentColor" strokeWidth="2.8" />
      <path d="M23 5.5L23 11.5" stroke="currentColor" strokeWidth="2.8" />
      <path d="M12 5.5L12 11.5" stroke="currentColor" strokeWidth="2.8" />
      <path d="M18 5.5L18 11.5" stroke="currentColor" strokeWidth="2.8" />
      <path d="M7 5.5L7 11.5" stroke="currentColor" strokeWidth="2.8" />
    </svg>
  );
};

export { Ram };
