const Users = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M20.4931 29.4856C19.0809 29.9559 17.5702 30.2105 16.0001 30.2105C8.15182 30.2105 1.78955 23.8482 1.78955 16C1.78955 8.15173 8.15182 1.78946 16.0001 1.78946C23.8483 1.78946 30.2106 8.15173 30.2106 16C30.2106 17.5418 29.9651 19.0263 29.5109 20.4164"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <circle
      cx="5"
      cy="5"
      r="5"
      transform="matrix(1 0 0 -1 10.7896 17.7895)"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M5.78955 26.7895L10.5825 21.7895H20.7896"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M29.7896 25.6462H21.7896"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M25.79 21.6462V29.6462" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Users };
