const Internet = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <ellipse
      cx="16"
      cy="16"
      rx="5"
      ry="14"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M3.55566 20.6667L28.4446 20.6667"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M3.55566 11.3333L28.4446 11.3333"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <circle cx="16" cy="16" r="14" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Internet };
