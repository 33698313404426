const Git = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M27 20C27 20.5523 26.5523 21 26 21C25.4477 21 25 20.5523 25 20C25 19.4477 25.4477 19 26 19C26.5523 19 27 19.4477 27 20Z"
      fill="currentColor"
    />
    <path
      d="M21 27C21 27.5523 20.5523 28 20 28C19.4477 28 19 27.5523 19 27C19 26.4477 19.4477 26 20 26C20.5523 26 21 26.4477 21 27Z"
      fill="currentColor"
    />
    <path
      d="M14.724 8.84339L25.3117 19.3117M20 14V25.9398M3.56738 20L20 3.56738L36.4326 20L20 36.4326L3.56738 20ZM27 20C27 20.5523 26.5523 21 26 21C25.4477 21 25 20.5523 25 20C25 19.4477 25.4477 19 26 19C26.5523 19 27 19.4477 27 20ZM21 27C21 27.5523 20.5523 28 20 28C19.4477 28 19 27.5523 19 27C19 26.4477 19.4477 26 20 26C20.5523 26 21 26.4477 21 27Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Git };
