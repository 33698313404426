const Checked = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={className}
    >
      <path
        d="M21.8809 12.1699L15.2812 18.7696L11.3335 14.8219"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <circle cx="16" cy="16" r="14" stroke="currentColor" strokeWidth="2.8" />
    </svg>
  );
};

export { Checked };
