const Orchestration = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="42"
    viewBox="0 0 32 42"
    fill="none"
  >
    <path
      d="M11.5035 37.2496L11.5035 27.7461L2 37.2496L11.5035 37.2496Z"
      fill="currentColor"
    />
    <path
      d="M16.56 6.71863C8.51873 6.71863 2 13.2373 2 21.2786C2 25.4124 3.72271 29.1438 6.48938 31.7941C6.78393 32.0763 7.0903 32.3462 7.40769 32.603"
      stroke="currentColor"
      strokeWidth="3.136"
    />
    <path
      d="M20.4965 4.75035L20.4965 14.2539L30 4.75037L20.4965 4.75035Z"
      fill="currentColor"
    />
    <path
      d="M15.44 35.2814C23.4813 35.2814 30 28.7627 30 20.7214C30 16.5876 28.2773 12.8562 25.5106 10.2059C25.2161 9.92374 24.9097 9.65384 24.5923 9.39701"
      stroke="currentColor"
      strokeWidth="3.136"
    />
    <path
      d="M15.1084 14.8541C15.4357 14.0572 16.5643 14.0572 16.8916 14.8541C17.542 16.4376 19.179 17.3827 20.8755 17.1542C21.7293 17.0393 22.2936 18.0166 21.7671 18.6985C20.721 20.0535 20.721 21.9438 21.7671 23.2988C22.2936 23.9807 21.7293 24.958 20.8755 24.8431C19.179 24.6146 17.542 25.5597 16.8916 27.1432C16.5643 27.9401 15.4357 27.9401 15.1084 27.1432C14.458 25.5597 12.821 24.6146 11.1245 24.8431C10.2707 24.958 9.70641 23.9807 10.2329 23.2988C11.279 21.9438 11.279 20.0535 10.2329 18.6985C9.70641 18.0166 10.2707 17.0393 11.1245 17.1542C12.821 17.3827 14.458 16.4376 15.1084 14.8541Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M16 22.3983V19.5983" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Orchestration };
