const Building = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M28 2H12.5083V11.75H2V28H14.1333H28V2Z"
      stroke="currentColor"
      strokeWidth="2.4375"
    />
    <path d="M19.875 6.875V9.3125" stroke="currentColor" strokeWidth="2.4375" />
    <path
      d="M19.875 13.375V15.8125"
      stroke="currentColor"
      strokeWidth="2.4375"
    />
    <path
      d="M19.875 19.875V22.3125"
      stroke="currentColor"
      strokeWidth="2.4375"
    />
    <rect x="6" y="20" width="8" height="8" fill="currentColor" />
  </svg>
);

export { Building };
