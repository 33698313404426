const Plane = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="35"
    viewBox="0 0 34 35"
    fill="none"
  >
    <path
      d="M3.75 12.5L28.75 31.5L32.25 2.5L3.75 12.5Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M31.25 4L13.75 21V29L19.25 25"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Plane };
