const Cerebro = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M5 23L8 33"
      stroke="currentColor"
      strokeWidth="2.8"
      strokeLinecap="round"
    />
    <path
      d="M35 23L32 33"
      stroke="currentColor"
      strokeWidth="2.8"
      strokeLinecap="round"
    />
    <circle cx="20" cy="21" r="3" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M17.846 15C11.8303 15.8728 7.0788 20.3441 6.16064 26V26.818M22.1537 15C28.3457 15.8984 33.1982 20.6091 33.9099 26.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M7 23V20C7 12.8203 12.8203 7 20 7C27.1797 7 33 12.8203 33 20V23"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M18 18.7691V18.5L16 7H24L22 18.5V18.7691"
      stroke="currentColor"
      strokeWidth="2.8"
      strokeLinecap="round"
    />
    <path
      d="M13.5 29H16"
      stroke="currentColor"
      strokeWidth="2.8"
      strokeLinecap="round"
    />
    <path
      d="M24 29H26.5"
      stroke="currentColor"
      strokeWidth="2.8"
      strokeLinecap="round"
    />
  </svg>
);

export { Cerebro };
