const Cpu = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <rect x="12.0605" y="12" width="6" height="6" fill="currentColor" />
      <path
        d="M4.35059 6.42862V23.5715L6.49344 25.7143H23.6363L25.7792 23.5715V6.42862L23.6363 4.28577H6.49344L4.35059 6.42862Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M15 3.33333V0" stroke="currentColor" strokeWidth="2.8" />
      <path d="M15 30.0001V26.6667" stroke="currentColor" strokeWidth="2.8" />
      <path
        d="M29.9998 15L26.6665 15"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M3.33333 15L0 15" stroke="currentColor" strokeWidth="2.8" />
      <path d="M21.6665 3.33333V0" stroke="currentColor" strokeWidth="2.8" />
      <path
        d="M21.6665 30.0001V26.6667"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M29.9998 8.33337L26.6665 8.33337"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M3.33333 8.33337L0 8.33337"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M8.3335 3.33333V0" stroke="currentColor" strokeWidth="2.8" />
      <path
        d="M8.3335 30.0001V26.6667"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M29.9998 21.6666L26.6665 21.6666"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M3.33333 21.6666L0 21.6666"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Cpu };
