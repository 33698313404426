const Accessibility = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={className}
    >
      <circle cx="16" cy="16" r="14" stroke="currentColor" strokeWidth="2.8" />
      <path d="M16 15V18.3333L10 25" stroke="currentColor" strokeWidth="2.8" />
      <path d="M16 18.3333L22 25" stroke="currentColor" strokeWidth="2.8" />
      <path d="M8 14H24" stroke="currentColor" strokeWidth="2.8" />
      <circle cx="16" cy="8" r="3" fill="currentColor" />
    </svg>
  );
};

export { Accessibility };
