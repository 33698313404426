const CloudPrivate = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="30"
      viewBox="0 0 36 30"
      fill="none"
    >
      <path
        d="M11.6 25.4H10C5.58203 25.4 2 21.8183 2 17.4C2 12.9817 5.58203 9.40001 10 9.40001C10 4.98173 13.582 1.40004 18 1.40004C22.418 1.40004 26 4.98173 26 9.40001C30.418 9.40001 34 12.9817 34 17.4C34 21.8183 30.418 25.4 26 25.4H25.2"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M18 21.6L18 24.4" stroke="currentColor" strokeWidth="2.8" />
      <rect
        x="11.6001"
        y="17.4"
        width="12.8"
        height="11.2"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M13.2002 15.8C13.2002 14.0327 14.6329 12.6 16.4002 12.6H19.6002C21.3675 12.6 22.8002 14.0327 22.8002 15.8V17.4H13.2002V15.8Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { CloudPrivate };
