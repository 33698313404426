const CloudDRaaS = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M13 30H12C7.58203 30 4 26.4183 4 22C4 17.5817 7.58203 14 12 14C12 9.58171 15.582 6 20 6C24.418 6 28 9.58171 28 14C32.418 14 36 17.5817 36 22C36 26.4183 32.0359 29.8919 27.3361 30.0207L26.4133 31.7375"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M20.1038 19.035C17.9992 18.9897 15.8801 19.7702 14.274 21.3762C11.1498 24.5004 11.1498 29.5658 14.274 32.69C17.3982 35.8141 22.4636 35.8141 25.5878 32.69C26.3805 31.8972 26.9722 30.9794 27.3626 30"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M25 19L19 13L19 25L25 19Z" fill="currentColor" />
  </svg>
);

export { CloudDRaaS };
