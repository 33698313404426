const LocationPoint = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="33"
    viewBox="0 0 30 33"
    fill="none"
  >
    <path
      d="M15 1.5C7.8203 1.5 2 7.27055 2 14.3889C2 16.7365 2.63306 18.9375 3.73917 20.8333L15 30.5L26.2608 20.8333C27.3669 18.9375 28 16.7365 28 14.3889C28 7.27055 22.1797 1.5 15 1.5Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <circle cx="15" cy="14.5" r="5" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { LocationPoint };
