const Backup = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M25.5 16.0001L19 22.5001L15 18.5001M20 34C27.732 34 34 27.732 34 20C34 12.268 27.732 6 20 6C12.268 6 6 12.268 6 20C6 23.9748 7.65646 27.5627 10.3167 30.1111C10.5999 30.3824 10.8945 30.6419 11.1997 30.8888L13.1159 32.3974M15 34.4939V30.4939L11 34.4939H15Z"
      stroke="#222222"
      strokeWidth="2.8"
    />
  </svg>
);

export { Backup };
