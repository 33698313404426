const CloudTechnology = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={className}
    >
      <path
        d="M12 29C7.58203 29 4 25.4183 4 21C4 16.5817 7.58203 13 12 13C12 8.58171 15.582 5 20 5C24.418 5 28 8.58171 28 13C32.418 13 36 16.5817 36 21C36 25.4183 32.418 29 28 29M15 24V34H5M25 24V34H35M20 19V35.3974"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M23 19H17L20 16L23 19Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { CloudTechnology };
