const CouchDB = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M32 15V14C32 11.7909 30.2091 10 28 10H12C9.79086 10 8 11.7909 8 14V15M30 29H10M30 23H10M6 18L6 30M34 18V30"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { CouchDB };
