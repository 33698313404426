const LogFile = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M25.8967 6.88086H12V18.5009M33.6171 14.6013V18.0009V31.5861H25.5H19.5"
        stroke="currentColor"
        strokeWidth="2.77263"
      />
      <path
        d="M25.6758 5.5V14.8254H35.0012V14.42L26.0812 5.5H25.6758Z"
        fill="currentColor"
      />
      <circle cx="14.0008" cy="26.5008" r="1.92857" fill="currentColor" />
      <path
        d="M12 18.5L9.5 18.5L8.11167 20.9681M16 18.5L18.5 18.5L19.908 21.0031M21.7981 24.3633L23 26.5L21.6036 28.9826M6.21551 24.3391L5 26.5L6.37338 28.9416M19.6937 32.3779L18.5 34.5L16 34.5M8.29337 32.3549L9.5 34.5L12 34.5"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M12 19.3164H16" stroke="currentColor" strokeWidth="2.8" />
      <path d="M12 33.6855H16" stroke="currentColor" strokeWidth="2.8" />
      <path
        d="M19 21.3672L21 24.9227"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M19 32.1074L21 28.5519"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M9 21.3672L7 24.9227" stroke="currentColor" strokeWidth="2.8" />
      <path d="M9 32.1074L7 28.5519" stroke="currentColor" strokeWidth="2.8" />
    </svg>
  );
};

export { LogFile };
