const MagnifyingGlass = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M28.2353 28.2353L34 34M32.3529 19.1765C32.3529 26.4536 26.4536 32.3529 19.1765 32.3529C11.8993 32.3529 6 26.4536 6 19.1765C6 11.8993 11.8993 6 19.1765 6C26.4536 6 32.3529 11.8993 32.3529 19.1765Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { MagnifyingGlass };
