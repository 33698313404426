const Media = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M2 5V25L5 28H25L28 25V5L25 2H5L2 5Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M2 10.125H28" stroke="currentColor" strokeWidth="2.8" />
    <path d="M12 24V14L22 19L12 24Z" fill="currentColor" />
  </svg>
);

export { Media };
