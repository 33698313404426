const Go = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M14.5782 20.0168H18.4632C18.3699 21.0616 17.8898 22.1266 17.0389 22.9775C15.2897 24.7267 12.6361 24.9091 11.1119 23.3849C9.58776 21.8607 9.77017 19.2071 11.5193 17.4579C13.2685 15.7088 15.9221 15.5264 17.4463 17.0505C17.592 17.1962 17.7221 17.3522 17.8367 17.5168M28.0926 22.9269C26.3434 24.6761 23.6898 24.8585 22.1656 23.3344C20.6415 21.8102 20.8239 19.1566 22.5731 17.4074C24.3222 15.6582 26.9758 15.4758 28.5 17C30.0242 18.5242 29.8418 21.1778 28.0926 22.9269ZM7 7H33V33H7V7Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Go };
