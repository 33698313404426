const CheckList = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M16 12.6465H30.979H34" stroke="currentColor" strokeWidth="2.8" />
      <path d="M34 22.6465H16" stroke="currentColor" strokeWidth="2.8" />
      <path d="M34 32.3516H16" stroke="currentColor" strokeWidth="2.8" />
      <path
        d="M10.1172 32.3516L7.31719 32.3516"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M12.4941 10.1992L8.55282 14.1405L5.9992 11.5869"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M12.4473 20.0938L8.53552 24.0055L6.00105 21.471"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { CheckList };
