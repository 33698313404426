const Star = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M20 6L24.9374 14.2043L34.2658 16.3647L27.9889 23.5957L28.8168 33.1353L20 29.4L11.1832 33.1353L12.0111 23.5957L5.73415 16.3647L15.0626 14.2043L20 6Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Star };
