const Ruble = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <rect
        x="2"
        y="2"
        width="26"
        height="26"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M11.5 5.79901L11.5 24.201"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M12.5 7.20099H17.5C19.7091 7.20099 21.5 8.99185 21.5 11.201V11.201C21.5 13.4101 19.7091 15.201 17.5 15.201H8.5"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M8.5 19.4474L19.5073 19.4474"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Ruble };
