const Archive = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <rect
        x="3.625"
        y="8.5"
        width="22.75"
        height="19.5"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <rect
        x="2"
        y="2"
        width="26"
        height="6.5"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M8.5 18.25L21.5 18.25" stroke="currentColor" strokeWidth="2.8" />
    </svg>
  );
};

export { Archive };
