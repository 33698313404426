const Medical = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M34 14.75L34 25.25L25.25 25.25L25.25 34L14.75 34L14.75 25.25L6 25.25L6 14.75L14.75 14.75L14.75 6L25.25 6L25.25 14.75L34 14.75Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Medical };
