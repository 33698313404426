const Repository = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="30"
    viewBox="0 0 32 30"
    fill="none"
  >
    <ellipse
      cx="14.5"
      cy="8"
      rx="12.5"
      ry="6"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M18 28H30V21.75V19.5625H24L21.75 18H18V28Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M14 20C7.37258 20 2 17.3137 2 14"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M14.5 26C7.59644 26 2 23.3137 2 20V8M27 8V15.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Repository };
