const Monitor = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M16.2707 22.4497L11.321 17.5L16.2707 12.5503M23.7293 22.4497L28.679 17.5L23.7293 12.5503M20 28V35M11 35H29M6 7H34V28H6V7Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Monitor };
