const Webinar = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <path d="M11 17V7L21 12L11 17Z" fill="currentColor" />
      <rect
        x="2"
        y="2"
        width="26"
        height="19.5"
        stroke="currentColor"
        strokeWidth="2.4375"
      />
      <path d="M15 22.3125V28" stroke="currentColor" strokeWidth="2.4375" />
      <path
        d="M6.875 28L23.125 28"
        stroke="currentColor"
        strokeWidth="2.4375"
      />
    </svg>
  );
};

export { Webinar };
