const Containers = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <rect
        x="2"
        y="18"
        width="10"
        height="10"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <rect
        x="2"
        y="8"
        width="10"
        height="10"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <rect
        x="12"
        y="18"
        width="10"
        height="10"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <rect
        x="18"
        y="2"
        width="10"
        height="10"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Containers };
