const Like = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path d="M23 30H30V15H23" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M6.81448 30H22.4615V14.8333L16.4434 2C13.5548 2 12.8326 4.33333 12.8326 5.5V11.3333H4.40724C2.48145 11.3333 2 12.8889 2 13.6667L3.20362 26.5C3.20362 29.3 5.61086 30 6.81448 30Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M2 17.6429H8.46154"
      stroke="currentColor"
      strokeWidth="2.8"
      strokeLinejoin="bevel"
    />
    <path
      d="M3.07715 24.1509H8.46176"
      stroke="currentColor"
      strokeWidth="2.8"
      strokeLinejoin="bevel"
    />
  </svg>
);

export { Like };
