const Team = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <circle cx="13" cy="11" r="3" stroke="currentColor" strokeWidth="2.8" />
    <circle cx="27" cy="23" r="3" stroke="currentColor" strokeWidth="2.8" />
    <path d="M9 25V32H16M31 16V9H24" stroke="currentColor" strokeWidth="2.8" />
    <path d="M6 21L9 18H17L20 21" stroke="currentColor" strokeWidth="2.8" />
    <path d="M20 33L23 30H31L34 33" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Team };
