const Electricity = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M2.7998 2.79999H25.1998M25.1998 25.2H2.7998M2.7998 10.3606V17.7803M25.1998 10.3606V17.7803"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <circle cx="3" cy="3" r="3" fill="currentColor" />
    <circle cx="25" cy="3" r="3" fill="currentColor" />
    <circle cx="3" cy="25" r="3" fill="currentColor" />
    <circle cx="25" cy="25" r="3" fill="currentColor" />
    <path d="M13 20L17 14H11L15.5 8" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Electricity };
