const Market = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="30"
    viewBox="0 0 32 30"
    fill="none"
  >
    <path
      d="M5.48037 7.23883H29.2802L26.1069 19.8446H8.65368L4.01558 2.26923H0.720215"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <circle cx="22.6894" cy="26.4354" r="3.29539" fill="currentColor" />
    <circle cx="11.7051" cy="26.4354" r="3.29539" fill="currentColor" />
  </svg>
);

export { Market };
