const Layers = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="30"
      viewBox="0 0 34 30"
      fill="none"
      className={className}
    >
      <path
        d="M17 1.77777L3 11.1111L17 20.4444L31 11.1111L17 1.77777Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M9.22222 15L3 19.3333L17 28.2222L31 19.3333L24.7778 15"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};
export { Layers };
