const Arenadata = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M33 13.5C33 17.0899 27.1797 20 20 20C12.8203 20 7 17.0899 7 13.5M33 13.5C33 9.91015 27.1797 7 20 7M33 13.5V26.5C33 30.0899 27.1797 33 20 33C12.8203 33 7 30.0899 7 26.5V13.5M7 13.5C7 9.91015 12.8203 7 20 7M20 7L19.9999 12.7265M19.9999 12.7265C17.4673 12.7265 15.1446 13.2045 13.3332 14L9.26363 9.83385M19.9999 12.7265C22.5325 12.7265 24.8553 13.2045 26.6666 14L30.6769 9.79075M33 20C33 23.5899 27.1797 26.5 20 26.5C12.8203 26.5 7 23.5899 7 20"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Arenadata };
