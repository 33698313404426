import cn from 'classnames';
import { useState } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { MultiSelect, Option } from 'react-multi-select-component';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { DataFromFormInputs } from 'src/types';

import s from './MultiSelect.module.scss';

interface MultiSelectControlProps {
  options: Option[];
  control: Control<DataFromFormInputs, any>;
  name: string;
  errors: FieldErrors<DataFromFormInputs>;
  required?: boolean;
  label: string;
  classNames?: {
    wrapper?: string;
  };
}

export function MultiSelectControl({
  options,
  control,
  name,
  errors,
  label,
  classNames,
  required = false,
  ...rest
}: MultiSelectControlProps) {
  const { clickAnalytics } = useAnalytics();
  const [labels, setLabels] = useState<Option[]>([]);
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required,
      }}
      render={({ field: { onChange, value } }) => (
        <div
          className={cn(s.multiSelectWrapper, classNames?.wrapper)}
          {...rest}
        >
          <MultiSelect
            className={cn(s.multiSelect, {
              [s.errorSelect]: errors.comment,
            })}
            options={options}
            value={value ? (value as Option[]) : []}
            onChange={(event: Option[]) => {
              onChange(event);
              if (event.length === 0) {
                if (labels.length > 1) {
                  clickAnalytics({
                    action: 'click',
                    clickZone: 'body',
                    clickElement: 'filter-select',
                    clickContent: 'clear-all-filters',
                    uniqueId: `multiselect-${name}`,
                    transitionType: 'inside-link',
                  });
                } else {
                  clickAnalytics({
                    action: 'click',
                    clickZone: 'body',
                    clickElement: 'filter-select',
                    clickContent: labels[0].label,
                    uniqueId: `multiselect-${name}`,
                    transitionType: 'inside-link',
                  });
                }
                setLabels([]);
                return;
              }

              if (event.length > labels.length) {
                setLabels(event);
                clickAnalytics({
                  action: 'click',
                  clickZone: 'body',
                  clickElement: 'filter-select',
                  clickContent: event[event.length - 1].label,
                  uniqueId: `multiselect-${name}`,
                  transitionType: 'inside-link',
                });
              } else {
                setLabels(event);
                const removedOption = labels.filter(
                  (label) => !event.map((i) => i.label).includes(label.label),
                );
                if (removedOption.length === 0) return;

                clickAnalytics({
                  action: 'click',
                  clickZone: 'body',
                  clickElement: 'filter-select',
                  clickContent: removedOption[0].label,
                  uniqueId: `multiselect-${name}`,
                  transitionType: 'inside-link',
                });
              }
            }}
            disableSearch
            hasSelectAll={false}
            labelledBy={label}
            overrideStrings={{
              selectSomeItems: label,
              allItemsAreSelected: 'Выбрано все',
            }}
          />
          {errors.comment && (
            <span className={cn(s.errorText)}>
              {errors?.message?.toString() ?? 'Обязательное поле'}
            </span>
          )}
        </div>
      )}
    />
  );
}
