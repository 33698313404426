const Restore = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="31"
      viewBox="0 0 32 31"
      fill="none"
      className={className}
    >
      <path
        d="M16 29.4239C23.732 29.4239 30 23.1559 30 15.4239C30 7.69195 23.732 1.42395 16 1.42395C8.26801 1.42395 2 7.69195 2 15.4239C2 19.3987 3.65646 22.9866 6.31672 25.535C6.59993 25.8063 6.89452 26.0658 7.1997 26.3128"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M16 6.5658V15.6246L23.5385 20.5658"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M11.1519 21.4247V30.5762H2L11.1519 21.4247Z"
        fill="currentColor"
      />
    </svg>
  );
};

export { Restore };
