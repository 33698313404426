const Globe = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <circle cx="16" cy="16" r="14" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M16 30V23.2308L20.3333 19H29"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M2 12H13.05L19 7V2" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Globe };
