const Lifebuoy = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <circle cx="16" cy="16" r="7" stroke="currentColor" strokeWidth="2.8" />
    <circle cx="16" cy="16" r="14" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M10.5558 12.1111L5.11133 6.66666"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M11.3331 20.6667L5.88867 26.1111"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M20.6669 12.1111L26.1113 6.66666"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M20.6669 20.6667L26.1113 26.1111"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Lifebuoy };
