const Application = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="30"
      viewBox="0 0 34 30"
      fill="none"
      className={className}
    >
      <circle cx="17" cy="15" r="4" stroke="currentColor" strokeWidth="2.8" />
      <path
        d="M13.6667 1.66666L9.5 1.66666L7.18612 5.78022M20.3333 1.66666L24.5 1.66666L26.8467 5.83851M29.9968 11.4388L32 15L29.6726 19.1376M4.02585 11.3985L2 15L4.28896 19.0693M26.4895 24.7964L24.5 28.3333L20.3333 28.3333M7.48895 24.7581L9.5 28.3333L13.6667 28.3333"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path d="M13.667 3.0257H20.3337" stroke="currentColor" strokeWidth="3" />
      <path d="M13.667 26.975H20.3337" stroke="currentColor" strokeWidth="3" />
      <path
        d="M25.3335 6.44702L28.6668 12.373"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path
        d="M25.3335 24.3472L28.6668 18.4213"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path
        d="M8.6665 6.44702L5.33317 12.373"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path
        d="M8.6665 24.3472L5.33317 18.4213"
        stroke="currentColor"
        strokeWidth="3"
      />
    </svg>
  );
};

export { Application };
