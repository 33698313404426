const Smartphone = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="32"
    viewBox="0 0 26 32"
    fill="none"
  >
    <path
      d="M2 5L5.14286 1.85715H20.8571L24 5V27L20.8571 30.1428H5.14286L2 27V5Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M9.33301 6.83334L16.6663 6.83334"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M13 24.25V26.8166" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Smartphone };
