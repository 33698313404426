const ClickHouse = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M8 8L8 32M14 8L14 32M20 8L20 32M26 8L26 32M32 17V23"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { ClickHouse };
