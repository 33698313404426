const Exchange = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M18.375 14.8L25.6875 7H33V13.825L29.8661 16.75M23.9 18.375L33 25.6875L33 33L25.0375 33L21.625 29.8661M21.625 25.2L14.3125 33H7V26.175L10.1339 23.25M14.8 21.625L7 14.3125L7 7L13.825 7L16.75 10.1339"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Exchange };
