const Time = ({
  className = '',
  isSmall,
}: {
  className?: string;
  isSmall?: boolean;
}) => {
  return (
    <>
      {!isSmall ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          className={className}
        >
          <circle
            cx="16"
            cy="16"
            r="14"
            stroke="currentColor"
            strokeWidth="3.01538"
          />
          <path
            d="M16 7.38464V16.4435L23.5385 21.3846"
            stroke="currentColor"
            strokeWidth="3.01538"
          />
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="6" stroke="black" strokeWidth="1.6" />
          <path
            d="M10 6.5V10.0833L12.5 12.5"
            stroke="black"
            strokeWidth="1.6"
          />
        </svg>
      )}
    </>
  );
};

export { Time };
