const MongoDB = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <path
        d="M15.0063 30L15.0061 25.2857M15.0061 25.2857C12.8174 24.1429 8.55952 20.3714 9.03704 14.4286C9.23609 12.5238 10.1938 7.57143 15.0063 3C16.996 4.90476 20.9753 9.85714 20.9753 14.4286C21.1741 17.2857 20.2586 23.4571 15.0061 25.2857Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M15 26L15 15" stroke="currentColor" strokeWidth="2.8" />
      <path
        d="M24 2H28V28H24M6 2H2V28H6"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { MongoDB };
