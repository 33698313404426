const Speech2 = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
  >
    <path
      d="M28 2H2V22.8H9.42857V28L20.5714 22.8H28V2Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M6.875 10.125L23.125 10.125"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M6.875 15L23.125 15" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Speech2 };
