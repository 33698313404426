const OnPerm = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="32"
    viewBox="0 0 30 32"
    fill="none"
  >
    <path
      d="M6.875 28.1H2V9.74703L15 2.09998L28 9.74703V28.1H23.125"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M23 18.4003L22 17.1H8L7 18.4003V22.3013L8 23.6016H22L23 22.3013V18.4003Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M22 23.3984L23 24.6988V28.5997L22 29.9001H8L7 28.5997V24.6988L8 23.3984"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { OnPerm };
