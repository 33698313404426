const Support = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8 29V17.9259C8 11.3394 13.3726 6 20 6C26.6274 6 32 11.3394 32 17.9259V29M26 30H32V21H26V30ZM26 30L20.5 35.5H17M8 21H14V30H8V21Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Support };
