const Dollar = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <rect
      x="2"
      y="2"
      width="26"
      height="26"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M12.9107 15H16.625C18.4199 15 19.875 16.4551 19.875 18.25V18.25C19.875 20.0449 18.4199 21.5 16.625 21.5H10.125"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M17.0893 15L13.375 15C11.5801 15 10.125 13.5449 10.125 11.75V11.75C10.125 9.95507 11.5801 8.5 13.375 8.5L19.875 8.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M15 8L15 2" stroke="currentColor" strokeWidth="2.8" />
    <path d="M15 22L15 28" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Dollar };
