const Nutanix = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M7 31L16 20L7 9M14 31L23 20L14 9M26 17L33.5 9M26 23L33.5 31"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Nutanix };
