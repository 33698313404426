const Arrows = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M20 6C12.268 6 6 12.268 6 20C6 23.9748 7.65646 27.5627 10.3167 30.1111C10.5999 30.3824 10.8945 30.6419 11.1997 30.8888L13.1159 32.3974M20 34.4939C27.732 34.4939 34 28.2259 34 20.4939C34 16.5191 32.3435 12.9312 29.6833 10.3828C29.4001 10.1115 29.1055 9.852 28.8003 9.60505L26.8841 8.09652M15 34.4939V30.4939L11 34.4939H15ZM25 6V10L29 6H25Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Arrows };
