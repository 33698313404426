const NodeJS = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M28.6333 18.6433C28.6333 17.9365 28.2667 16.5229 26.8 15.8162C25.3333 15.1094 22.4 14.4025 19.8333 15.8161C17.2667 17.2297 18.0251 20.1464 20.5 20.5C24 21 27.5333 21 28.6333 22.5306C29.5627 23.8238 29 25.7113 27.5333 26.4179C25.8935 27.208 20.6891 27.6891 19 26C18 25 18 24 18 23.2373M16.75 33.4375L20 35.3333L33 27.75V12.5833L20 5L7 12.5833V27.75L10 29.5C12 30.5 14 29.25 14 27V14"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { NodeJS };
