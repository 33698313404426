const EyeOn = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="24"
      viewBox="0 0 32 24"
      fill="none"
      className={className}
    >
      <path
        d="M16 2C6.2 2 2 12 2 12C2 12 5.5 22 16 22C26.5 22 30 12 30 12C30 12 25.8 2 16 2Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <circle cx="16" cy="12" r="4" stroke="currentColor" strokeWidth="2.8" />
    </svg>
  );
};

export { EyeOn };
