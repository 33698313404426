const SpineSwitch = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
  >
    <rect
      x="1.63623"
      y="1.63599"
      width="26"
      height="26"
      stroke="currentColor"
      strokeWidth="2.4375"
    />
    <path
      d="M5.87891 5.87861L14.3642 5.87861L5.87891 14.3639L5.87891 5.87861Z"
      fill="currentColor"
    />
    <path
      d="M5.87891 24.1213L14.3642 24.1213L5.87891 15.636L5.87891 24.1213Z"
      fill="currentColor"
    />
    <path
      d="M24.1216 5.87861L15.6363 5.87861L24.1216 14.3639L24.1216 5.87861Z"
      fill="currentColor"
    />
    <path
      d="M24.1216 24.1213L15.6363 24.1213L24.1216 15.636L24.1216 24.1213Z"
      fill="currentColor"
    />
    <path
      d="M9.63623 20.1985L20.1987 9.63599"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M9.63623 9.63599L20.1987 20.1985"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { SpineSwitch };
