const History = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M20 34C27.732 34 34 27.732 34 20C34 12.268 27.732 6 20 6C12.268 6 6 12.268 6 20C6 23.9748 7.65646 27.5627 10.3167 30.1111C10.5999 30.3824 10.8945 30.6419 11.1997 30.8888L13.35 32.65M13 14H27M13 19H27M13.1159 24H27M15 34.5V31L11.5 34.5H15Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { History };
