const Calendar = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <rect
        x="2"
        y="3.4375"
        width="26"
        height="24.375"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M6.875 6.6875V0.1875" stroke="currentColor" strokeWidth="2.8" />
      <path d="M23.125 6.6875V0.1875" stroke="currentColor" strokeWidth="2.8" />
      <path d="M2 10.75H28" stroke="currentColor" strokeWidth="2.8" />
      <path d="M7 15.5V18.3" stroke="currentColor" strokeWidth="2.8" />
      <path d="M7 20.375V23.175" stroke="currentColor" strokeWidth="2.8" />
      <path d="M11.875 15.5V18.3" stroke="currentColor" strokeWidth="2.8" />
      <path d="M16.75 15.5V18.3" stroke="currentColor" strokeWidth="2.8" />
      <path d="M11.875 20.375V23.175" stroke="currentColor" strokeWidth="2.8" />
      <path d="M16.75 20.375V23.175" stroke="currentColor" strokeWidth="2.8" />
      <path d="M21.625 15.5V18.3" stroke="currentColor" strokeWidth="2.8" />
    </svg>
  );
};

export { Calendar };
