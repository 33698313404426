const AI = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M9 29H7.6V29.5799L8.01005 29.99L9 29ZM9 11L8.01005 10.0101L7.6 10.4201V11H9ZM11 9V7.6H10.4201L10.0101 8.01005L11 9ZM29 9L29.99 8.01005L29.5799 7.6H29V9ZM31 11H32.4V10.4201L31.99 10.0101L31 11ZM31 29L31.99 29.99L32.4 29.5799V29H31ZM29 31V32.4H29.5799L29.99 31.99L29 31ZM11 31L10.0101 31.99L10.4201 32.4H11V31ZM11.6718 25.5573C11.4273 26.2908 11.8238 27.0836 12.5573 27.3282C13.2908 27.5727 14.0836 27.1762 14.3282 26.4427L11.6718 25.5573ZM17 14V12.6C16.3974 12.6 15.8624 12.9856 15.6718 13.5573L17 14ZM18 14L19.3282 13.5573C19.1376 12.9856 18.6026 12.6 18 12.6V14ZM20.6718 26.4427C20.9164 27.1762 21.7092 27.5727 22.4427 27.3282C23.1762 27.0836 23.5727 26.2908 23.3282 25.5573L20.6718 26.4427ZM27.4 14C27.4 13.2268 26.7732 12.6 26 12.6C25.2268 12.6 24.6 13.2268 24.6 14H27.4ZM24.6 26C24.6 26.7732 25.2268 27.4 26 27.4C26.7732 27.4 27.4 26.7732 27.4 26H24.6ZM10.4 29V11H7.6V29H10.4ZM9.98995 11.9899L11.9899 9.98995L10.0101 8.01005L8.01005 10.0101L9.98995 11.9899ZM11 10.4H29V7.6H11V10.4ZM28.01 9.98995L30.01 11.9899L31.99 10.0101L29.99 8.01005L28.01 9.98995ZM29.6 11V29H32.4V11H29.6ZM30.01 28.01L28.01 30.01L29.99 31.99L31.99 29.99L30.01 28.01ZM29 29.6H11V32.4H29V29.6ZM11.9899 30.01L9.98995 28.01L8.01005 29.99L10.0101 31.99L11.9899 30.01ZM21.4 9V5H18.6V9H21.4ZM21.4 35V31H18.6V35H21.4ZM35 19.6H31V22.4H35V19.6ZM9 19.6H5V22.4H9V19.6ZM28.4 9V5H25.6V9H28.4ZM28.4 35V31H25.6V35H28.4ZM35 12.6H31V15.4H35V12.6ZM9 12.6H5V15.4H9V12.6ZM14.4 9V5H11.6V9H14.4ZM14.4 35V31H11.6V35H14.4ZM35 26.6H31V29.4H35V26.6ZM9 26.6H5V29.4H9V26.6ZM14.3282 26.4427L15.3282 23.4427L12.6718 22.5573L11.6718 25.5573L14.3282 26.4427ZM15.3282 23.4427L18.3282 14.4427L15.6718 13.5573L12.6718 22.5573L15.3282 23.4427ZM17 15.4H18V12.6H17V15.4ZM16.6718 14.4427L19.6718 23.4427L22.3282 22.5573L19.3282 13.5573L16.6718 14.4427ZM19.6718 23.4427L20.6718 26.4427L23.3282 25.5573L22.3282 22.5573L19.6718 23.4427ZM13.9479 24.4H14V21.6H13.9479V24.4ZM14 24.4H21V21.6H14V24.4ZM24.6 14V26H27.4V14H24.6Z"
      fill="currentColor"
    />
  </svg>
);

export { AI };
