const Cloud = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 16C28 11.5817 24.418 8 20 8C15.582 8 12 11.5817 12 16C7.58203 16 4 19.5817 4 24C4 28.4183 7.58203 32 12 32H28C32.418 32 36 28.4183 36 24C36 19.5817 32.418 16 28 16Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Cloud };
