const CloudMove = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="30"
      viewBox="0 0 36 30"
      fill="none"
      className={className}
    >
      <path
        d="M26 26C30.418 26 34 22.4183 34 18C34 13.5817 30.418 10 26 10C26 5.58171 22.418 2 18 2C13.582 2 10 5.58171 10 10C5.58203 10 2 13.5817 2 18C2 20.1804 2.87233 22.157 4.28696 23.6H15.6"
        stroke="#222222"
        strokeWidth="2.8"
      />
      <path d="M21 23.5L15 17L15 30L21 23.5Z" fill="currentColor" />
    </svg>
  );
};

export { CloudMove };
