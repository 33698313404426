const BarChart = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M2 5V25L5 28H25L28 25V5L25 2H5L2 5Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M21 9L21 23" stroke="currentColor" strokeWidth="2.8" />
    <path d="M15 13L15 23" stroke="currentColor" strokeWidth="2.8" />
    <path d="M9 17L9 23" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { BarChart };
