const Kafka = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M15 25C17.7614 25 20 22.7614 20 20C20 17.2386 17.7614 15 15 15M15 25C12.2386 25 10 22.7614 10 20C10 17.2386 12.2386 15 15 15M15 25V29M15 15V11M15 11C16.6569 11 18 9.65685 18 8C18 6.34315 16.6569 5 15 5C13.3431 5 12 6.34315 12 8C12 9.65685 13.3431 11 15 11ZM15 29C13.3431 29 12 30.3431 12 32C12 33.6569 13.3431 35 15 35C16.6569 35 18 33.6569 18 32C18 30.3431 16.6569 29 15 29ZM19.3687 17.5445L24.5001 14.4999M24.1299 25.2737L19.2642 22.515M30 13C30 14.6569 28.6569 16 27 16C25.3431 16 24 14.6569 24 13C24 11.3431 25.3431 10 27 10C28.6569 10 30 11.3431 30 13ZM30 26C30 27.6569 28.6569 29 27 29C25.3431 29 24 27.6569 24 26C24 24.3431 25.3431 23 27 23C28.6569 23 30 24.3431 30 26Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Kafka };
