const ContainerApps = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M4 21C4 16.5817 7.58203 13 12 13C12 8.58171 15.582 5 20 5C24.418 5 28 8.58171 28 13C32.418 13 36 16.5817 36 21C36 25.4183 32.418 29 28 29H20M6 31V25L11 22L16 25V31L11 34L6 31Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { ContainerApps };
