import cn from 'classnames';
import { CSSProperties, forwardRef } from 'react';

import s from './Wrapper.module.scss';

interface WrapperProps {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
}

export const Wrapper = forwardRef<HTMLDivElement, WrapperProps>(
  ({ children, className = '', style }, ref) => {
    return (
      <div ref={ref} className={cn(s.wrapper, className)} style={style}>
        {children}
      </div>
    );
  },
);

Wrapper.displayName = 'Wrapper';
