const BackupRepository = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
  >
    <ellipse
      cx="14"
      cy="7.75635"
      rx="12.5"
      ry="6"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M13.5 19.7563C6.87258 19.7563 1.5 17.0701 1.5 13.7563"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M14 25.7563C7.09644 25.7563 1.5 23.0701 1.5 19.7563V7.75635M26.5 7.75635V15.2563"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M19.3988 27.8447C19.277 27.7461 19.1594 27.6425 19.0463 27.5342C17.9845 26.517 17.3232 25.0848 17.3232 23.4982C17.3232 20.4118 19.8252 17.9098 22.9116 17.9098C25.998 17.9098 28.5 20.4118 28.5 23.4982C28.5 25.9462 26.926 28.0265 24.7349 28.7824"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M21.8887 23.2438V30.2438H14.8887L21.8887 23.2438Z"
      fill="currentColor"
    />
  </svg>
);

export { BackupRepository };
