const Laptop = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="30"
      viewBox="0 0 33 30"
      fill="none"
      className={className}
    >
      <path
        d="M22.0154 5H3.9375V22H27.9375V10.8784"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M0.9375 28H31.0625" stroke="currentColor" strokeWidth="2.8" />
      <path d="M21.9375 7H25.9375V11" stroke="currentColor" strokeWidth="2.8" />
      <path d="M23.9375 2H30.9375V9" stroke="currentColor" strokeWidth="2.8" />
    </svg>
  );
};

export { Laptop };
