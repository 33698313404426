const Industrial = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="32"
    viewBox="0 0 30 32"
    fill="none"
  >
    <rect
      x="22"
      y="2"
      width="6"
      height="28"
      stroke="currentColor"
      strokeWidth="2.4375"
    />
    <path d="M16.625 18.25V21.05" stroke="currentColor" strokeWidth="2.8" />
    <path d="M6.875 18.25V21.05" stroke="currentColor" strokeWidth="2.8" />
    <path d="M16.625 23.125V25.925" stroke="currentColor" strokeWidth="2.8" />
    <path d="M6.875 23.125V25.925" stroke="currentColor" strokeWidth="2.8" />
    <path d="M2 16L12 9V30H2V16Z" stroke="currentColor" strokeWidth="2.4375" />
    <path
      d="M12 15.3333L22 8V30H12V15.3333Z"
      stroke="currentColor"
      strokeWidth="2.4375"
    />
  </svg>
);

export { Industrial };
