const SadSmile = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M14.6154 27.5385C15.9838 26.2093 17.8908 25.3846 20 25.3846C22.1092 25.3846 24.0162 26.2093 25.3846 27.5385M15 15V18.0154M25 15V18.0154M34 20C34 27.732 27.732 34 20 34C12.268 34 6 27.732 6 20C6 12.268 12.268 6 20 6C27.732 6 34 12.268 34 20Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { SadSmile };
