const Exit = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M11 20L25 20M19 33H32V7L19 7M8 20L11 17V23L8 20Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Exit };
