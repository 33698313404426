const MINDController = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M20 20H12C12 15.5817 15.5817 12 20 12V20ZM20 20H28C28 24.4183 24.4183 28 20 28V20ZM7 7H33V33H7V7Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { MINDController };
