const Python = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M27.3419 12.9V8.70463L24.9091 6.44556H16.2203L13.7875 8.70463V12.9M13.7875 12.9H20.7385M13.7875 12.9H9.96443L7.01025 15.0865V24.744L9.26933 26.7772H12.3973M26.2125 27.0999L26.2125 31.2953L23.7797 33.5544L15.091 33.5544L12.6581 31.2953L12.6581 20.6454H27.3447V13.2228L30.7307 13.2228L32.9898 15.2559L32.9898 24.9135L30.0356 27.0999L26.2125 27.0999ZM26.2125 27.0999H19.2616"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Python };
