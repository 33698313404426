const CloudIaaS = ({ className = '' }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="30"
    viewBox="0 0 36 30"
    fill="none"
    className={className}
  >
    <path
      d="M10 25.5C5.58203 25.5 2 21.9183 2 17.5C2 13.0817 5.58203 9.5 10 9.5C10 5.08171 13.582 1.5 18 1.5C22.418 1.5 26 5.08171 26 9.5C30.418 9.5 34 13.0817 34 17.5C34 21.9183 30.418 25.5 26 25.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <rect
      x="11"
      y="21.5"
      width="7"
      height="7"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <rect
      x="18"
      y="21.5"
      width="7"
      height="7"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <rect
      x="18"
      y="14.5"
      width="7"
      height="7"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { CloudIaaS };
