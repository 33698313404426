const Alarm = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
  >
    <path d="M0 29.5H30" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M8.47826 8.5H20.6522L25 24.5H5L8.47826 8.5Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6494 19.8684L14.6494 8.86836L17.5078 8.86835L20.5668 19.8684H17.6494Z"
      fill="currentColor"
    />
    <path d="M15 4.5V0.5" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M24.0002 6.32843L26.8286 3.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M5.82843 6.32843L3 3.5" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Alarm };
