const Ringbell = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="30"
    viewBox="0 0 32 30"
    fill="none"
  >
    <path d="M14 28.5H18" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M6.87234 4.8V18L3 23.5H29L24.5745 18V4.8L21.2553 1.5H10.1915L6.87234 4.8Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Ringbell };
