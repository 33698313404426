const Tape = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <rect
      x="2"
      y="2"
      width="26"
      height="26"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <circle
      cx="9.5"
      cy="14.5"
      r="3.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <circle
      cx="20.5"
      cy="14.5"
      r="3.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M10 18L21 18" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Tape };
