const Bridge = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <rect
      x="2"
      y="2.19946"
      width="26"
      height="26"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M6.875 23.3245L6.87522 7.07446"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M23.125 23.3245L23.1252 7.07446"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M23.125 20.0745C23.125 15.5871 19.4873 11.9495 15 11.9495C10.5127 11.9495 6.875 15.5871 6.875 20.0745"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Bridge };
