const Shield = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 34V6" stroke="#222222" strokeWidth="2.8" />
      <path
        d="M30.8746 28.5627L20 34L9.12537 28.5627C7.20994 27.605 6 25.6473 6 23.5057V6H34V23.5057C34 25.6472 32.7901 27.605 30.8746 28.5627Z"
        stroke="#222222"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Shield };
