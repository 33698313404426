const LoadBalancer = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M28 20H14M28 31H21L21 9L28 9M6 28L6 12L14 12L14 28L6 28ZM28 34L28 28H34L34 34H28ZM28 23V17H34V23H28ZM28 12V6L34 6V12L28 12Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { LoadBalancer };
