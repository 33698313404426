const ProcessTree = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <rect
      x="12"
      y="2"
      width="8"
      height="8"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <rect
      x="2"
      y="24"
      width="6"
      height="6"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <rect
      x="13"
      y="24"
      width="6"
      height="6"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <rect
      x="24"
      y="24"
      width="6"
      height="6"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M16 23L16 11" stroke="currentColor" strokeWidth="2.8" />
    <path d="M5 24V17H27V24" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { ProcessTree };
