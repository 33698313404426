const CloudLightning = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="28"
    viewBox="0 0 36 28"
    fill="none"
  >
    <path
      d="M16.9331 22.8L21.1998 16.4H14.7998L19.5998 10"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 10C26 5.58171 22.418 2 18 2C13.582 2 10 5.58171 10 10C5.58203 10 2 13.5817 2 18C2 22.4183 5.58203 26 10 26H26C30.418 26 34 22.4183 34 18C34 13.5817 30.418 10 26 10Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { CloudLightning };
