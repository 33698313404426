const HardDrive2 = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
  >
    <path
      d="M33 27L29.75 24H10.25L7 27M11 29H13.8M7 31V11L10 8H30L33 11V31L30 34H10L7 31Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { HardDrive2 };
