const Dialog = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <path
      d="M25.0588 6.94116H2V25.3882H8.58823L13.5294 30L18.4706 25.3882H25.0588V6.94116Z"
      stroke="currentColor"
      strokeWidth="3.01538"
    />
    <path
      d="M6.94141 6.52941V2H30.0002V20.1176H25.0591"
      stroke="currentColor"
      strokeWidth="3.01538"
    />
    <path
      d="M6.94141 13.5294L20.1179 13.5294"
      stroke="currentColor"
      strokeWidth="3.01538"
    />
    <path
      d="M6.94141 18.4706L20.1179 18.4706"
      stroke="currentColor"
      strokeWidth="3.01538"
    />
  </svg>
);

export { Dialog };
