const HybridCloud = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="34"
    viewBox="0 0 36 34"
    fill="none"
  >
    <path
      d="M11 26H10C5.58203 26 2 22.4183 2 18C2 13.5817 5.58203 10 10 10C10 5.58171 13.582 2 18 2C22.418 2 26 5.58171 26 10C30.418 10 34 13.5817 34 18C34 22.4183 30.418 26 26 26H25"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M20 16L16 12V20L20 16Z" fill="currentColor" />
    <path d="M16 30L20 34V26L16 30Z" fill="currentColor" />
    <path
      d="M18 16.0249C14.134 16.0249 11 19.1589 11 23.0249C11 25.6159 12.4077 27.8781 14.5 29.0884M18 30.0249C21.866 30.0249 25 26.8909 25 23.0249C25 20.8143 23.9753 18.8431 22.375 17.5602"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { HybridCloud };
