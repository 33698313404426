const PublicCloud = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="30"
    viewBox="0 0 36 30"
    fill="none"
  >
    <ellipse
      cx="17.9995"
      cy="20.6"
      rx="8"
      ry="7.99997"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M11.6 25.4H10C5.58203 25.4 2 21.8183 2 17.4C2 12.9817 5.58203 9.40001 10 9.40001C10 4.98173 13.582 1.40004 18 1.40004C22.418 1.40004 26 4.98173 26 9.40001C30.418 9.40001 34 12.9817 34 17.4C34 21.8183 30.418 25.4 26 25.4H25.2"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M17.9995 28.6V24.6L20.4995 22.1H25.4995"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M9.99951 18.6H16.4995L19.9995 15.6V12.6"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { PublicCloud };
