const Sun = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M15.7572 15.7654L8.67529 8.68378M31.3263 31.3348L24.2392 24.2477M14.0039 20H4M36 20H26.0042M20 4V14M20 14C16.6863 14 14 16.6863 14 20C14 23.3137 16.6863 26 20 26C23.3137 26 26 23.3137 26 20C26 16.6863 23.3137 14 20 14ZM20 25.9976V36M32.1469 7.8396L24.2833 15.7032M15.755 24.2315L8.65576 31.3307"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Sun };
