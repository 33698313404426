const EyeOff = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M27.7 27.811C25.7086 29.0862 23.1741 30 20 30C9.5 30 6 20 6 20C6 20 7.95213 15.3521 12.3 12.3937M31.2 24.6926C33.1953 22.2992 34 20 34 20C34 20 29.8 10 20 10C18.6536 10 17.413 10.1887 16.2744 10.5144M22.9702 22.9699C21.33 24.6101 18.6707 24.6101 17.0305 22.9699C15.3903 21.3297 15.3903 18.6704 17.0305 17.0302M8 8L32 32"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { EyeOff };
