const Instance = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M2 17.1667V25.8333L4.16667 28H12.8333L15 25.8333V17.1667L12.8333 15H4.16667L2 17.1667Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M8.5 2H2V8.5M21.5 2H28V8.5M28 21.5V28H21.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Instance };
