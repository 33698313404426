const FileIcon = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="29"
      viewBox="0 0 25 29"
      fill="none"
      className={className}
    >
      <path
        d="M15.3967 2.3374H1.5V27.0427H23.1171V10.0578"
        stroke="currentColor"
        strokeWidth="2.77263"
      />
      <path
        d="M15.1748 0.957397V10.2828H24.5002V9.87737L15.5803 0.957397H15.1748Z"
        fill="currentColor"
      />
      <path
        d="M6.13174 21.2523L18.4844 21.2523"
        stroke="currentColor"
        strokeWidth="2.77263"
      />
      <path
        d="M6.13174 16.6201L18.4844 16.6201"
        stroke="currentColor"
        strokeWidth="2.77263"
      />
    </svg>
  );
};

export { FileIcon as File };
