const ApacheFlink = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M15.2148 28.8491C14.2556 24.1794 16.1741 22.1041 21.9293 20.0286C26.5334 18.3682 27.4746 14.1903 27.2049 12.2458C26.2809 5.58504 18.0925 6.36547 15.2148 8.61384C18.668 10.2742 17.961 12.7066 15.6945 13.2835C12.9768 13.9753 7.4455 16.8117 7.06181 22.6229C6.67813 28.4341 11.698 31.9623 14.2558 33H29.1233C29.1233 31.9623 27 32.6132 27 29.5M26.7724 15.602C28.371 16.4668 32.0009 19.3022 32.0009 22.6229C32.0009 26.7738 27 26.3868 27 29.5M27 29.5C27 30.8807 28.1193 32 29.5 32C30.8807 32 32 30.8807 32 29.5C32 28.1193 30.8807 27 29.5 27C28.1193 27 27 28.1193 27 29.5ZM27 21V23.8"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { ApacheFlink };
