const Velocity = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M25.1111 28.5L28 25.6111V18.5H2V25.6111L4.88889 28.5H25.1111Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M14 18.5L20.5 12" stroke="currentColor" strokeWidth="2.8" />
    <path d="M15 2.5V7" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M4.59229 7L7.77427 10.182"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M25.5317 7L22.3498 10.182"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M2 19.5V14.5C2 7.3203 7.8203 1.5 15 1.5C22.1797 1.5 28 7.3203 28 14.5V19.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Velocity };
