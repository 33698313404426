const CrossProcess = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M2 21.0607H9.91667L16.25 9.06067H21"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M2 9.06067H9.91667L16.25 21.0607H21"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <rect
      x="2"
      y="2"
      width="26"
      height="26"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <rect
      x="2"
      y="2"
      width="26"
      height="26"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M25 21.0607L20 26.0607V16.0607L25 21.0607Z" fill="currentColor" />
    <path d="M25 9.06067L20 14.0607V4.06067L25 9.06067Z" fill="currentColor" />
  </svg>
);

export { CrossProcess };
