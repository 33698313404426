const DocumentLocked = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <path d="M22.5 22.6V25.4" stroke="currentColor" strokeWidth="2.8" />
      <rect
        x="17"
        y="19.5"
        width="11"
        height="9"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M18 17.3889C18 15.7934 19.2934 14.5 20.8889 14.5H24.1111C25.7066 14.5 27 15.7934 27 17.3889V19.5H18V17.3889Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M7.77783 7.27777L19.3334 7.27777"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M7.77783 11.6111L19.3334 11.6111"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M13.5556 24.6111H2V1.5H25.1111V12.3333"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M7.77783 7.27777L19.3334 7.27777"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { DocumentLocked };
