const NAS = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M2 5.08835V25.3106L4.88889 28.1995H25.1111L28 25.3106V5.08835L25.1111 2.19946H4.88889L2 5.08835Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M6.875 7.07446H9.3125" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M18.25 7.07446L18.25 23.3245L23.125 23.3245L23.125 7.07446L18.25 7.07446Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M18.25 7.07446L13.375 7.07446L13.375 23.3245L18.25 23.3245"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { NAS };
