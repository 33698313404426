const Hadoop = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M13.1366 7.5L7 32.5H15.0044L17.9655 19.9214H23.035C24.6892 19.9214 24.5691 21.5659 24.3023 22.3881L21.8082 32.5H29.4125L32.9478 17.7666C33.3747 14.4066 31.0801 13.6555 29.8794 13.7H19.5403L21.1412 7.5H13.1366Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Hadoop };
