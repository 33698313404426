const OpenSearch = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M23.1201 28.0205C23.1201 31.467 20.3261 34.2609 16.8796 34.2609C13.4331 34.2609 10.6392 31.467 10.6392 28.0205C10.6392 24.574 13.4331 21.78 16.8796 21.78C20.3261 21.78 23.1201 24.574 23.1201 28.0205Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M29.3605 11.9795C29.3605 15.426 26.5666 18.2199 23.1201 18.2199C19.6736 18.2199 16.8796 15.426 16.8796 11.9795C16.8796 8.53296 19.6736 5.73901 23.1201 5.73901C26.5666 5.73901 29.3605 8.53296 29.3605 11.9795Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M12.7595 15.3196C12.7595 17.4737 11.0133 19.2199 8.85927 19.2199C6.7052 19.2199 4.95898 17.4737 4.95898 15.3196C4.95898 13.1655 6.7052 11.4193 8.85927 11.4193C11.0133 11.4193 12.7595 13.1655 12.7595 15.3196Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M35.041 24.6803C35.041 26.8343 33.2948 28.5806 31.1407 28.5806C28.9867 28.5806 27.2404 26.8343 27.2404 24.6803C27.2404 22.5262 28.9867 20.78 31.1407 20.78C33.2948 20.78 35.041 22.5262 35.041 24.6803Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { OpenSearch };
