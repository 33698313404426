const Table = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M2 4.88889V25.1111L4.88889 28H25.1111L28 25.1111V4.88889L25.1111 2H4.88889L2 4.88889Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M2 10.125H28" stroke="currentColor" strokeWidth="2.8" />
    <path d="M10.125 2L10.125 28" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Table };
