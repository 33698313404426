const SpeechBubble = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
    >
      <path
        d="M2 15C2 22.1797 7.8203 28 15 28H28L25.7499 22.3125C27.1699 20.2291 28 17.7115 28 15C28 7.8203 22.1797 2 15 2C7.8203 2 2 7.8203 2 15Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M7.94141 13.6V16.4" stroke="currentColor" strokeWidth="2.8" />
      <path d="M15.0005 13.6V16.4" stroke="currentColor" strokeWidth="2.8" />
      <path d="M22.0591 13.6V16.4" stroke="currentColor" strokeWidth="2.8" />
    </svg>
  );
};

export { SpeechBubble };
