const Router = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <circle
      cx="16"
      cy="16"
      r="14"
      stroke="currentColor"
      strokeWidth="3.01538"
    />
    <path d="M8.22217 16H1.99995" stroke="currentColor" strokeWidth="3.01538" />
    <path
      d="M12.7694 16.0653L7.38477 21.4499L7.38477 10.6807L12.7694 16.0653Z"
      fill="currentColor"
    />
    <path d="M23.7778 16H30.0001" stroke="currentColor" strokeWidth="3.01538" />
    <path
      d="M19.2306 16.0653L24.6152 21.4499V10.6807L19.2306 16.0653Z"
      fill="currentColor"
    />
    <path
      d="M16 9.77759L16 22.222"
      stroke="currentColor"
      strokeWidth="3.01538"
    />
    <path
      d="M15.9998 5.29605L21.3845 10.6807L10.6152 10.6807L15.9998 5.29605Z"
      fill="currentColor"
    />
    <path
      d="M15.9998 26.8346L21.3845 21.45L10.6152 21.45L15.9998 26.8346Z"
      fill="currentColor"
    />
  </svg>
);

export { Router };
