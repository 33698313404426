const WAN = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <ellipse
      cx="16"
      cy="16"
      rx="6"
      ry="14"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M3 21L13 21" stroke="currentColor" strokeWidth="2.8" />
    <path d="M19 21L28.3333 21" stroke="currentColor" strokeWidth="2.8" />
    <path d="M3 12L13 12" stroke="currentColor" strokeWidth="2.8" />
    <path d="M19 12L28.3333 12" stroke="currentColor" strokeWidth="2.8" />
    <circle cx="16" cy="16" r="14" stroke="currentColor" strokeWidth="2.8" />
    <path d="M17.3999 12L14.5999 12" stroke="currentColor" strokeWidth="2.8" />
    <path d="M17.3999 21L14.5999 21" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { WAN };
