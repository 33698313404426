const CloudRepository = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="30"
    viewBox="0 0 32 30"
    fill="none"
  >
    <ellipse
      cx="14.3423"
      cy="8.21057"
      rx="12.5"
      ry="6"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.0791 21.4036C27.0791 19.6401 25.7005 18.2106 24.0002 18.2106C22.2998 18.2106 20.9212 19.6401 20.9212 21.4036C19.2209 21.4036 17.8423 22.8331 17.8423 24.5965C17.8423 26.36 19.2209 27.7895 20.9212 27.7895H27.0791C28.7795 27.7895 30.1581 26.36 30.1581 24.5965C30.1581 22.8331 28.7795 21.4036 27.0791 21.4036Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M13.8423 20.2106C7.21487 20.2106 1.84229 17.5243 1.84229 14.2106"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M14.3423 26.2106C7.43873 26.2106 1.84229 23.5243 1.84229 20.2106V8.21057M26.8423 8.21057V15.7106"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { CloudRepository };
