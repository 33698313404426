const Fingerprint = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="34"
    viewBox="0 0 26 34"
    fill="none"
  >
    <path
      d="M1.5 5.50001L4.78571 2.21429H21.2143L24.5 5.50001V28.5L21.2143 31.7857H4.78571L1.5 28.5V5.50001Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M18.833 13V8H6.83301V17M18.833 17V26H6.83301V21"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M12.833 12V22" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Fingerprint };
