const Transport = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="30"
    viewBox="0 0 32 30"
    fill="none"
  >
    <path
      d="M5.5 23.5H2V1.5H24V22M10 23.5H20.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M24 22.5V11.5L30 16.3889V23.5H25.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <circle cx="23" cy="25.5" r="3" stroke="currentColor" strokeWidth="2.8" />
    <circle cx="8" cy="25.5" r="3" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Transport };
