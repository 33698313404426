const SDCard = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M28 28.1995V2.19946H8.5L2 8.69946V28.1995H28Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M13.375 2.19946L13.375 7.07446"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M18.25 2.19946L18.25 7.07446"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M23.125 2.19946L23.125 7.07446"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M23.125 28.1995V18.4495H6.875V28.1995"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { SDCard };
