const DataLabs = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="34"
    viewBox="0 0 30 34"
    fill="none"
  >
    <path
      d="M20 7.53125V1.53125H10V7.53125"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M7 1.53125H23" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M14.1084 13.3867C14.4357 12.5898 15.5643 12.5898 15.8916 13.3867C16.542 14.9702 18.179 15.9153 19.8755 15.6868C20.7293 15.5719 21.2936 16.5492 20.7671 17.2311C19.721 18.5861 19.721 20.4764 20.7671 21.8314C21.2936 22.5133 20.7293 23.4906 19.8755 23.3757C18.179 23.1472 16.542 24.0923 15.8916 25.6758C15.5643 26.4727 14.4357 26.4727 14.1084 25.6758C13.458 24.0923 11.821 23.1472 10.1245 23.3757C9.27069 23.4906 8.70641 22.5133 9.23287 21.8314C10.279 20.4764 10.279 18.5861 9.23287 17.2311C8.70641 16.5492 9.27069 15.5719 10.1245 15.6868C11.821 15.9153 13.458 14.9702 14.1084 13.3867Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <circle
      cx="15"
      cy="19.4688"
      r="13"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M15 20.9312V18.1312" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { DataLabs };
