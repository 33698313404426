const Delete = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="30"
    viewBox="0 0 26 30"
    fill="none"
  >
    <path
      d="M3 8.5V26.186L5.59259 28.5H20.4074L23 26.186V8.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M0 8.72223L26 8.72223" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M6 4.38889C6 2.7934 7.2934 1.5 8.88889 1.5H17.1111C18.7066 1.5 20 2.7934 20 4.38889V8.5H6V4.38889Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Delete };
