const Data = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.32134 3.12871L0.532238 5.74788L0.532316 3.01091L4.52156 0.139648H7.12134V12.273H4.32134V3.12871Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.668 3.12871L20.8789 5.74788L20.879 3.01091L24.8682 0.139648H27.468V12.273H24.668V3.12871Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.668 18.7161L20.8789 21.3353L20.879 18.5983L24.8682 15.7271H27.468V27.8604H24.668V18.7161Z"
        fill="currentColor"
      />
      <rect
        x="2.25488"
        y="17.473"
        width="5.2"
        height="8.66667"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <rect
        x="12.6548"
        y="17.473"
        width="5.2"
        height="8.66667"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <rect
        x="12.6548"
        y="1.87305"
        width="5.2"
        height="8.66667"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Data };
