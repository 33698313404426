const Energo = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="34"
    viewBox="0 0 26 34"
    fill="none"
  >
    <path
      d="M13 1.98215C6.64873 1.98215 1.5 7.20549 1.5 13.6488C1.5 16.9133 2.82162 19.8646 4.95167 21.9821C5.44397 22.5377 5.60714 24.4821 6.42857 26.9821H19.5714C20.3929 23.6488 20.556 22.8155 21.0483 21.9821C23.1784 19.8646 24.5 16.9133 24.5 13.6488C24.5 7.20549 19.3513 1.98215 13 1.98215Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M18.5 14.125C18.5 10.8113 15.8137 8.125 12.5 8.125"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M6 32.0179H20" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Energo };
