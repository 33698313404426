const VDI = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <rect
      x="2"
      y="2"
      width="28"
      height="21"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M16 23.875V30" stroke="currentColor" strokeWidth="2.8" />
    <path d="M7.25 30L24.75 30" stroke="currentColor" strokeWidth="2.8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.316 10.7807C19.316 8.88161 17.8314 7.3421 16.0002 7.3421C14.1691 7.3421 12.6844 8.88161 12.6844 10.7807C10.8533 10.7807 9.36865 12.3202 9.36865 14.2193C9.36865 16.1184 10.8533 17.6579 12.6844 17.6579H19.316C21.1472 17.6579 22.6318 16.1184 22.6318 14.2193C22.6318 12.3202 21.1472 10.7807 19.316 10.7807Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { VDI };
