const PostgreSQL = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M25.2438 17.4046C25.2438 17.8649 24.8707 18.238 24.4105 18.238C23.9502 18.238 23.5771 17.8649 23.5771 17.4046C23.5771 16.9444 23.9502 16.5713 24.4105 16.5713C24.8707 16.5713 25.2438 16.9444 25.2438 17.4046Z"
      fill="currentColor"
    />
    <path
      d="M18.6366 16.2059C18.3395 19.0526 16.8245 24.746 13.1411 24.746C8.53677 24.746 8.07999 11.834 10.2619 9.24802C13.6949 5.17931 30.1434 6.55104 31.7742 14.038C32.3188 16.5382 31.6325 22.2657 24.8004 27.7909C23.4636 28.8719 22.4341 30.9279 23.4636 32.7665C24.8004 35.1538 27.5481 34.7716 29.6274 33.2121M25.7382 25.2504L29.1986 28.8192M25.2438 17.4046C25.2438 17.8649 24.8707 18.238 24.4105 18.238C23.9502 18.238 23.5771 17.8649 23.5771 17.4046C23.5771 16.9444 23.9502 16.5713 24.4105 16.5713C24.8707 16.5713 25.2438 16.9444 25.2438 17.4046Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { PostgreSQL };
