const Grafana = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M30.0882 22.3305C31.6992 23.5779 32.863 26.0686 33.0134 26.7654C32.532 27.7501 30.7037 28.0459 29.8498 28.0707C29.0134 29.4983 27.9042 30.2142 27.1765 30.6954C27.0695 32.1652 26.4111 34.0885 25.7507 34.4574C24.5032 34.7724 22.8702 33.3431 22.0973 32.3577C19.7637 32.3577 18.8064 32.1438 17.9005 31.8521C16.5913 33.2636 14.0339 33.8536 13.024 33.5915C12.1329 32.1917 12.6527 29.7034 13.024 28.7848C12.3516 27.9847 11.7886 27.1847 11.2849 26.3846C7.98067 26.8059 6.28372 25.2743 5.84863 24.1802C5.84863 21.9404 8.39433 19.9621 9.76065 19.5539C9.76065 17.7906 10.1914 16.778 10.5207 15.9362C8.52465 13.5215 8.62727 11.608 8.86479 10.7477C10.1479 9.38283 13.4093 10.179 14.8796 10.7477C16.0134 9.99829 17.4966 9.36242 18.2657 9.17286C18.4796 6.9293 19.9291 5.78833 20.6271 5.49829C21.9331 5.70176 23.1964 7.6564 23.434 8.73546L26.4191 9.56657C27.5178 8.73546 28.8993 8.51672 29.2706 8.73546C30.1426 9.24923 30.1913 10.7963 30.1319 11.642C31.6835 12.982 33.0134 14.8509 34.1518 16.9667"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M22.0645 22.945C19.7094 22.945 19.1937 21.5182 19.2516 20.5335C19.3493 18.8684 20.8474 18.1221 22.0645 18.1221C23.7366 18.1221 26.0249 19.4052 26.0249 22.7177C26.0249 26.0302 23.2703 27.5699 21.3293 27.5699C15.6821 27.5699 14.2703 23.1664 14.2703 20.9647C14.2703 16.8997 16.9819 13.703 21.3293 13.4409C25.9838 13.1603 30.2914 17.01 30.5913 21.6083L30.5913 22.945L31.4658 23.7962"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Grafana };
