const Kubernetes = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M20.6239 20C20.6239 20.5523 20.1762 21 19.6239 21C19.0716 21 18.6239 20.5523 18.6239 20C18.6239 19.4477 19.0717 19 19.624 19C20.1763 19 20.6239 19.4477 20.6239 20Z"
      fill="currentColor"
    />
    <path
      d="M19.624 19L19.6317 9.14795M19.624 19C19.0717 19 18.6239 19.4477 18.6239 20C18.6239 20.5523 19.0716 21 19.6239 21C20.1762 21 20.6239 20.5523 20.6239 20C20.6239 19.4477 20.1763 19 19.624 19ZM18.9058 20.7075L13.5 28.5M20.3604 20.7075L26.1322 28.5M18.7269 19.5579L9.5 15.5M20.5344 19.5575L29.5 15.5M19.6239 5L31.3514 10.6477L34.2478 23.3378L26.1322 33.5145H13.1157L5 23.3378L7.89645 10.6477L19.6239 5ZM26.5675 19.7371C26.5675 23.6931 23.3605 26.9 19.4046 26.9C15.4487 26.9 12.2418 23.6931 12.2418 19.7371C12.2418 15.7812 15.4487 12.5743 19.4046 12.5743C23.3605 12.5743 26.5675 15.7812 26.5675 19.7371Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Kubernetes };
