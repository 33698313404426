const Console = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <path
        d="M2 4.88889V25.1111L4.88889 28H25.1111L28 25.1111V4.88889L25.1111 2H4.88889L2 4.88889Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M2 10.125H28" stroke="currentColor" strokeWidth="2.8" />
      <path d="M6.875 4.8219V7.6219" stroke="currentColor" strokeWidth="2.8" />
      <path
        d="M6.59619 14L11.1924 18.5962L6.59619 23.1924"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M15 22L24 22" stroke="currentColor" strokeWidth="3.01538" />
    </svg>
  );
};

export { Console };
