const NSXVMWare = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M33 7.00012H7V33.0001M33 7.00012V33.0001H7M33 7.00012L7 33.0001M14 14.0002L26.0002 26M12 12.0002H16L12 16.0002V12.0002ZM28 28.0002H24L28 24.0002V28.0002Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { NSXVMWare };
