const Sber = ({ className = '' }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="30"
    viewBox="0 0 29 30"
    fill="none"
  >
    <path
      d="M28.7862 14.8004C28.7867 13.9116 28.7095 13.0245 28.5553 12.15L25.4533 14.4956C25.4533 14.5948 25.4533 14.6976 25.4533 14.8004C25.455 17.3447 24.6278 19.8163 23.1036 21.821C21.5795 23.8256 19.4463 25.2478 17.0442 25.8607C14.6421 26.4736 12.1096 26.2418 9.85059 25.2024C7.59158 24.163 5.73622 22.3759 4.58046 20.126C3.4247 17.8761 3.03513 15.2932 3.47389 12.7891C3.91264 10.2851 5.15444 8.00417 7.00122 6.31037C8.84799 4.61648 11.1933 3.60721 13.6632 3.44351C16.133 3.27981 18.585 3.97112 20.628 5.40717L23.4267 3.2812C20.8871 1.17421 17.7263 0.0172616 14.4629 0.000191627C11.1995 -0.0168784 8.02733 1.10695 5.46701 3.18727C2.9067 5.26758 1.11028 8.18077 0.372532 11.4489C-0.365222 14.717 -0.000476599 18.1459 1.40691 21.1728C2.8143 24.1997 5.18074 26.6449 8.11789 28.1072C11.0551 29.5694 14.3885 29.9619 17.5712 29.2201C20.7538 28.4784 23.5967 26.6464 25.6333 24.025C27.6699 21.4035 28.7793 18.1482 28.7793 14.7933"
      fill="currentColor"
    />
    <path
      d="M25.7724 5.77197C26.4565 6.67977 27.0349 7.66677 27.4957 8.71287L14.3986 18.634L8.92188 15.0908V10.8389L14.3986 14.3821L25.7724 5.77197Z"
      fill="currentColor"
    />
  </svg>
);

export { Sber };
