const LocalNetwork = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      className={className}
    >
      <path
        d="M28 24V6H23.5C22.5 4.66667 19.4 2 15 2C10.6 2 7.5 4.66667 6.5 6H2V24H28Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M15 24.3125V30" stroke="currentColor" strokeWidth="2.8" />
      <path d="M6.875 30L23.125 30" stroke="currentColor" strokeWidth="2.8" />
      <path
        d="M10 12C10.9167 10.2219 12.8204 9 15.0201 9C17.1882 9 19.0686 10.1871 20 11.9237"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M15 14.6001V17.4001" stroke="currentColor" strokeWidth="2.8" />
    </svg>
  );
};

export { LocalNetwork };
