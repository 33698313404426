const Edit = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M3.09903 21.4521L21.8124 2.73873L27.0226 7.94899L8.30929 26.6623L3.10512 26.6562L3.09903 21.4521Z"
      stroke="currentColor"
      strokeWidth="3.01538"
    />
    <path d="M15.5 26.689H27.5" stroke="currentColor" strokeWidth="3.01538" />
  </svg>
);

export { Edit };
