const Databriks = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="34"
      viewBox="0 0 30 34"
      fill="none"
      className={className}
    >
      <path
        d="M24.3682 16.9299L28.333 19.2427V23.7285L15 31.5061L1.66699 23.7285V19.2427L5.63182 16.9299"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M1.66699 10.2715V14.7573L15 22.5348L28.333 14.7573V10.2715L15 2.4939L1.66699 10.2715Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Databriks };
