const Play = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path d="M18 24V16L25 20L18 24Z" fill="currentColor" />
    <path
      d="M34 20C34 27.732 27.732 34 20 34C12.268 34 6 27.732 6 20C6 12.268 12.268 6 20 6C27.732 6 34 12.268 34 20Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M18 24V16L25 20L18 24Z" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Play };
