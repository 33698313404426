const Billing = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M15.0437 26H17.1537C18.1734 26 19 26.8954 19 28C19 29.1046 18.1734 30 17.1537 30H12.5M17 26H15.1537C13.9642 26 13 25.1046 13 24C13 22.8954 13.9642 22 15.1537 22H19.5M16 22V17M16 17C11.0294 17 7 21.0294 7 26C7 30.9706 11.0294 35 16 35M16 17C20.9706 17 25 21.0294 25 26C25 30.9706 20.9706 35 16 35M16 30V35M24.0001 6H11.0001L11.0001 14M24.0001 6V15H33.0001M24.0001 6H24.3914L33.0001 14.6087V15M33.0001 15V31H28.0001"
      stroke="currentColor"
      strokeWidth="2.77263"
    />
  </svg>
);

export { Billing };
