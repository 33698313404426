const VApp = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path d="M24 12H28V16H24V12Z" fill="currentColor" />
    <path d="M12 12H16V16H12V12Z" fill="currentColor" />
    <path d="M24 24H28V28H24V24Z" fill="currentColor" />
    <path d="M12 24H16V28H12V24Z" fill="currentColor" />
    <path
      d="M16 16L24 24M16 16V12H12V16H16ZM24 24H28V28H24V24ZM24 16L16 24M24 16H28V12H24V16ZM16 24H12V28H16V24ZM7 7H33V33H7V7Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { VApp };
