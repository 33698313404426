const DataCenterServer = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <path
        d="M28 21.6994L26 19.5327H4L2 21.6994V26.0327L4 28.1994H26L28 26.0327V21.6994Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M28 13.0329L26 10.8662H4L2 13.0329V17.3662L4 19.5329H26L28 17.3662V13.0329Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M28 4.36613L26 2.19946H4L2 4.36613V8.69946L4 10.8661H26L28 8.69946V4.36613Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M15 23.8661H25" stroke="currentColor" strokeWidth="2.8" />
      <path d="M15 15.1995H25" stroke="currentColor" strokeWidth="2.8" />
      <path d="M15 6.53271H25" stroke="currentColor" strokeWidth="2.8" />
      <path d="M7 22.4661V25.2661" stroke="currentColor" strokeWidth="2.8" />
      <path d="M7 13.7996V16.5996" stroke="currentColor" strokeWidth="2.8" />
      <path d="M7 5.13281V7.93281" stroke="currentColor" strokeWidth="2.8" />
    </svg>
  );
};

export { DataCenterServer };
