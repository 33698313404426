const Puzzle = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={className}
    >
      <path
        d="M7 20L20 20M20 20L33 20M20 20V33M20 20L20 7M7 7H33V33H7V7Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M28 19C28 19.5523 27.5523 20 27 20C26.4477 20 26 19.5523 26 19C26 18.4477 26.4477 18 27 18C27.5523 18 28 18.4477 28 19Z"
        fill="currentColor"
      />
      <path
        d="M19 13C19.5523 13 20 13.4477 20 14C20 14.5523 19.5523 15 19 15C18.4477 15 18 14.5523 18 14C18 13.4477 18.4477 13 19 13Z"
        fill="currentColor"
      />
      <path
        d="M13 21C13 20.4477 13.4477 20 14 20C14.5523 20 15 20.4477 15 21C15 21.5523 14.5523 22 14 22C13.4477 22 13 21.5523 13 21Z"
        fill="currentColor"
      />
      <path
        d="M20 27C20 26.4477 20.4477 26 21 26C21.5523 26 22 26.4477 22 27C22 27.5523 21.5523 28 21 28C20.4477 28 20 27.5523 20 27Z"
        fill="currentColor"
      />
      <path
        d="M28 19C28 19.5523 27.5523 20 27 20C26.4477 20 26 19.5523 26 19C26 18.4477 26.4477 18 27 18C27.5523 18 28 18.4477 28 19Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M19 13C19.5523 13 20 13.4477 20 14C20 14.5523 19.5523 15 19 15C18.4477 15 18 14.5523 18 14C18 13.4477 18.4477 13 19 13Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M13 21C13 20.4477 13.4477 20 14 20C14.5523 20 15 20.4477 15 21C15 21.5523 14.5523 22 14 22C13.4477 22 13 21.5523 13 21Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M20 27C20 26.4477 20.4477 26 21 26C21.5523 26 22 26.4477 22 27C22 27.5523 21.5523 28 21 28C20.4477 28 20 27.5523 20 27Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Puzzle };
