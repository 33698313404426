const UseCases = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M35 25H8.15385L6 27.25V31.75L8.15385 34H35M17.3677 6L14.0771 6L12.2497 9.24866M22.6327 6L25.9233 6L27.7765 9.29469M30.2644 13.7175L31.8463 16.5299L30.0083 19.7976M9.75394 13.6856L8.15404 16.5299L9.96173 19.7436M17.3673 7.07318H22.6323M26.5811 9.77528L29.2136 14.4552M25.9977 24.4617L29.2135 19.2314M13.4193 9.77528L10.7868 14.4552M13.7882 24.4905L10.7871 19.232M33.9999 25C28.9508 25.45 28.39 33.1 34 34M11 25V34.0001M23 16.7589C23 18.4157 21.6569 19.7589 20 19.7589C18.3431 19.7589 17 18.4157 17 16.7589C17 15.102 18.3431 13.7589 20 13.7589C21.6569 13.7589 23 15.102 23 16.7589Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { UseCases };
