const PowerOff = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M14.2728 8.30769C9.39628 10.5084 6 15.4331 6 21.1553C6 28.929 12.268 35.2308 20 35.2308C27.732 35.2308 34 28.929 34 21.1553C34 15.4331 30.6037 10.5084 25.7272 8.30769M20 4L20 15.682"
      stroke="currentColor"
      strokeWidth="3.01538"
    />
  </svg>
);

export { PowerOff };
