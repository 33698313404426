const List = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <path
        d="M2 4.88889V25.1111L4.88889 28H25.1111L28 25.1111V4.88889L25.1111 2H4.88889L2 4.88889Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M13.375 8.5H23.125" stroke="currentColor" strokeWidth="2.8" />
      <path d="M13.375 15H23.125" stroke="currentColor" strokeWidth="2.8" />
      <path d="M13.375 21.5H23.125" stroke="currentColor" strokeWidth="2.8" />
      <path d="M6.875 8.5H9.675" stroke="currentColor" strokeWidth="2.8" />
      <path d="M6.875 15H9.675" stroke="currentColor" strokeWidth="2.8" />
      <path d="M6.875 21.5H9.675" stroke="currentColor" strokeWidth="2.8" />
    </svg>
  );
};

export { List };
