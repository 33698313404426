const Container = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M7 12.4168V27.5835L20 35.1668M7 12.4168L20 4.8335L33 12.4168M7 12.4168L19.9998 20.0002M33 12.4168V27.5835L20 35.1668M33 12.4168L19.9998 20.0002M20 35.1668L19.9998 20.0002"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Container };
