const Trino = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M15.0004 19.7402V5M25.1273 19.7402L32.4365 6.66667M7 30V23M33 30V23M29.1032 25.8333C29.1032 29.9755 24.9991 33.5 19.9365 33.5C14.8739 33.5 10.9998 30.1421 10.9998 26C10.9998 21.8579 14.9372 18.5 19.9998 18.5C25.0624 18.5 29.1032 21.6912 29.1032 25.8333Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Trino };
