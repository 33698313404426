const Bank = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      className={className}
    >
      <path d="M12 12V24" stroke="currentColor" strokeWidth="2.8" />
      <path d="M6 12V24" stroke="currentColor" strokeWidth="2.8" />
      <path d="M18 12V24" stroke="currentColor" strokeWidth="2.8" />
      <path d="M24 12V24" stroke="currentColor" strokeWidth="2.8" />
      <path
        d="M28 11.5H2V7.9L15 2.5L28 7.9V11.5Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <rect
        x="2"
        y="24.5"
        width="26"
        height="5"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Bank };
