const Corax = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M26.6667 20.3333C26.6667 22.1743 28.159 23.6667 30 23.6667C31.8409 23.6667 33.3333 22.1743 33.3333 20.3333C33.3333 18.4924 31.8409 17 30 17C28.159 17 26.6667 18.4924 26.6667 20.3333ZM26.6667 20.3333H15M29 10.3104C28.0493 9.47289 26.9739 8.76983 25.8029 8.22922C23.1084 6.9852 20.0661 6.67439 17.1697 7.34722C14.2732 8.02004 11.6927 9.63703 9.84663 11.9358C8.00061 14.2346 6.99748 17.0802 7 20.011C7.00253 22.9419 8.01058 25.7858 9.86056 28.0815C11.7106 30.3772 14.2939 31.9899 17.1915 32.6578C20.0891 33.3258 23.1308 33.0099 25.8232 31.7614C26.9864 31.222 28.0548 30.5222 29 29.6896"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Corax };
