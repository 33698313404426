const Datacenter = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <path d="M11 7.19946H19" stroke="currentColor" strokeWidth="2.8" />
      <path d="M11 12.1995H19" stroke="currentColor" strokeWidth="2.8" />
      <rect
        x="6.875"
        y="2.19946"
        width="16.25"
        height="26"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <rect
        x="23.125"
        y="7.07446"
        width="4.875"
        height="21.125"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <rect
        x="2"
        y="7.07446"
        width="4.875"
        height="21.125"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <circle cx="15" cy="20.1995" r="3" fill="currentColor" />
    </svg>
  );
};

export { Datacenter };
