const BubbleLike = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5 20C5 28.2843 11.7157 35 20 35H35L32.4037 28.4375C34.0422 26.0335 35 23.1286 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M23.75 17.7917L20 10C18.2 10 17.75 11.4167 17.75 12.125V15.6667H12.5C11.3 15.6667 11 16.6111 11 17.0833L11.75 24.875C11.75 26.575 13.25 27 14 27H23.75M23.75 17.7917H28V27H23.75M23.75 17.7917V27"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { BubbleLike };
