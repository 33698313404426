const RocketFly = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      className={className}
    >
      <path
        d="M23.1265 11.8734L21.4692 13.5307"
        stroke="currentColor"
        strokeWidth="2.34375"
      />
      <path
        d="M18.7075 16.2929L17.0502 17.9502"
        stroke="currentColor"
        strokeWidth="2.34375"
      />
      <path
        d="M17.6024 8.5589L7.65876 18.5026L12.0782 22.922L16.4976 27.3414L26.4413 17.3977C30.4188 13.4203 30.4924 10.4003 29.7558 5.24434C25.6311 4.65508 21.4694 4.69191 17.6024 8.5589Z"
        stroke="currentColor"
        strokeWidth="2.34375"
      />
      <path
        d="M5.36088 11.9618H13.8462L6.06798 19.74L1.82534 15.4974L5.36088 11.9618Z"
        stroke="currentColor"
        strokeWidth="2.34375"
      />
      <path
        d="M23.0381 29.6395V21.1542L15.2599 28.9324L19.5026 33.1751L23.0381 29.6395Z"
        stroke="currentColor"
        strokeWidth="2.34375"
      />
      <path
        d="M11.6708 23.3249L3.99805 30.9977"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M6.99805 22.0832L3.99805 25.0832"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M12.998 28.0832L9.99805 31.0832"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { RocketFly };
