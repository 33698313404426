const Point = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
  >
    <path
      d="M28.2077 9.20771L24.1154 32.0077L9.50002 28.5L5.40771 14.4692L28.2077 9.20771Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M9.43862 28.4386L27.4386 10.4386"
      stroke="currentColor"
      strokeWidth="2.8"
      strokeLinejoin="bevel"
    />
    <path
      d="M31.4385 6.23848L34.2385 3.43848"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M26.4184 5.39823V1.43844"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M32.4386 10.4184H36.3984"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Point };
