const DragAndDrop = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
  >
    <path
      d="M13.8896 13.4081L7.38965 19.9081"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M17.3364 9.96103H10.4421L17.3364 16.8553V9.96103Z"
      fill="currentColor"
    />
    <rect x="0.5" y="18.3611" width="8" height="8" fill="currentColor" />
    <path
      d="M2.5 7.63889V1.63889H8.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M25.5 7.63889V1.63889H19.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M25.5 18.6389V24.6389H19.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { DragAndDrop };
