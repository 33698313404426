const Mortarboard = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="30"
    viewBox="0 0 36 30"
    fill="none"
  >
    <path d="M33 11.25V27.25" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M18 2.25L3 11.25L18 20.25L33 11.25L18 2.25Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M8 14.25V22.4208L18 27.75L28 22.4208V14.25"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Mortarboard };
