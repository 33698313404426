const Certificate = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      className={className}
    >
      <path d="M8 8L18.9474 8" stroke="currentColor" strokeWidth="2.8" />
      <path d="M8 13L18.9474 13" stroke="currentColor" strokeWidth="2.8" />
      <path
        d="M13.5 24.75H1.5V1.75H25.5V13.25"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M21.658 14.5658L23.9102 18.3081L28.1653 19.2936L25.3021 22.5919L25.6797 26.9433L21.658 25.2395L17.6363 26.9433L18.014 22.5919L15.1508 19.2936L19.4059 18.3081L21.658 14.5658Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Certificate };
