const KubernetesJob = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="32"
    viewBox="0 0 34 32"
    fill="none"
  >
    <path
      d="M17 2L28.7275 7.64765L31.6239 20.3378L23.5083 30.5145H10.4917L2.37608 20.3378L5.27253 7.64765L17 2Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M20.8556 9V13.0959H25V9H20.8556ZM20.8556 14.89V18.9859H25V14.89H20.8556ZM9.03889 14.952V19.048H13.1833V14.952H9.03889ZM14.9444 14.952V19.048H19.0889V14.952H14.9444ZM9 20.9041V25H13.1444V20.9041H9ZM14.9278 20.9041V25H19.0722V20.9041H14.9278ZM20.8556 20.9041V25H25V20.9041H20.8556Z"
      fill="currentColor"
    />
  </svg>
);

export { KubernetesJob };
