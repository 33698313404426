const Book = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M2 1.75L15 3.75L28 1.75V24.75L15 28.25L2 24.75V1.75Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M15 3.75V27.75" stroke="currentColor" strokeWidth="2.8" />
    <path d="M18 8.75L25 8.75" stroke="currentColor" strokeWidth="2.8" />
    <path d="M5 8.75L12 8.75" stroke="currentColor" strokeWidth="2.8" />
    <path d="M18 14.75L25 14.75" stroke="currentColor" strokeWidth="2.8" />
    <path d="M18 20.75L25 20.75" stroke="currentColor" strokeWidth="2.8" />
    <path d="M5 14.75L12 14.75" stroke="currentColor" strokeWidth="2.8" />
    <path d="M5 20.75H12" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Book };
