const Folder = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="28"
    viewBox="0 0 30 28"
    fill="none"
  >
    <path
      d="M7.60773 10.9412L3.5293 26.2353H24.6012L27.9999 10.9412H25.2809H7.60773Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M24.9412 26.2353H2V1.76471H9.16912L13.4706 5.58824H24.9412C24.9412 7.41882 24.9412 9.19364 24.9412 10.9412"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Folder };
