const DataGrid = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M20.5146 24.1219C11.8474 21.3932 12.3664 16.0769 12.3664 12.792C5.16741 20.5778 8.53112 28.9386 15.2172 29.6672C21.058 30.3037 23.6945 32.0606 24.6191 33.2385C25.7134 27.7669 22.48 24.9499 20.5146 24.1219Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M29.2824 17.9777C25.531 10.9162 28.862 8.5848 30.7726 6.547C22.1953 6.8549 19.3941 12.933 22.2012 18.1924C24.5002 22.4999 23.0002 24.9999 24.0002 26.9999C28.2608 24.4875 30.7725 21.4999 29.2824 17.9777Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { DataGrid };
