const Rocket = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="30"
      viewBox="0 0 28 30"
      fill="none"
      className={className}
    >
      <path
        d="M14 10.875V13.2188"
        stroke="currentColor"
        strokeWidth="2.34375"
      />
      <path
        d="M14 17.125V19.4688"
        stroke="currentColor"
        strokeWidth="2.34375"
      />
      <path
        d="M7.75 12.4375V26.5H20.25V12.4375C20.25 6.8125 18.1667 4.625 14 1.5C10.6667 4 7.75 6.96875 7.75 12.4375Z"
        stroke="currentColor"
        strokeWidth="2.34375"
      />
      <path
        d="M1.5 23.5L7.5 17.5V28.5H1.5V23.5Z"
        stroke="currentColor"
        strokeWidth="2.34375"
      />
      <path
        d="M26.5 23.5L20.5 17.5V28.5H26.5V23.5Z"
        stroke="currentColor"
        strokeWidth="2.34375"
      />
      <path
        d="M21.5 4.5C24.8333 4.5 26.5 4.83333 26.5 9.5"
        stroke="currentColor"
        strokeWidth="2.34375"
      />
      <path
        d="M6.5 4.5C3.16667 4.5 1.5 4.83333 1.5 9.5"
        stroke="currentColor"
        strokeWidth="2.34375"
      />
    </svg>
  );
};

export { Rocket };
