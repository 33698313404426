const SpeechBubble2 = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M12 12H22M12 17H22M28 14H34V30.8H28V35L19 30.8H13V26M17 26C23.0751 26 28 21.0751 28 15C28 8.92487 23.0751 4 17 4C10.9249 4 6 8.92487 6 15C6 17.2943 6.70242 19.4246 7.90393 21.1875L6 26H17Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { SpeechBubble2 };
