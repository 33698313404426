const Robot = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
  >
    <path
      d="M5 14.75V26.6667L7.59259 29.4166H22.4074L25 26.6667V14.75"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M25 14.75C25 9.68742 20.5228 5.58337 15 5.58337C9.47715 5.58337 5 9.68742 5 14.75"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M15 5.58337V0.583374" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M11.6665 13.9167V16.7167"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M18.75 13.9167V16.7167" stroke="currentColor" strokeWidth="2.8" />
    <rect y="13.5834" width="5" height="7" fill="currentColor" />
    <rect x="25" y="13.5834" width="5" height="7" fill="currentColor" />
  </svg>
);

export { Robot };
