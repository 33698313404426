const Filter = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M16 21V34L24 31V21L34 8H6L16 21Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Filter };
