const ServerGlacier = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M8.82617 23.7534L6.82617 25.92V30.2534L8.82617 32.42H19.8262M8.82617 23.7534L6.82617 21.5867V17.2534L8.82617 15.0867M8.82617 23.7534L19.8262 23.7534M30.8262 15.0867L32.8262 17.2534V21.5867M30.8262 15.0867H8.82617M30.8262 15.0867L32.8262 12.92V8.58671L30.8262 6.42004H8.82617L6.82617 8.58671V12.92L8.82617 15.0867M19.8262 10.7533H29.8262M19.8262 19.4201H29.8262M11.8262 26.6867V29.4867M11.8262 18.0201V20.8201M11.8262 9.35339V12.1534"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M35.1738 29.4556H21.1738"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M31.6738 23.3933L24.6738 35.5177"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M24.6738 23.4556L31.6738 35.58"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { ServerGlacier };
