const Library = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="28"
    viewBox="0 0 26 28"
    fill="none"
  >
    <path
      d="M20.1875 26.4384V2.00092H1.5V23.5634"
      stroke="currentColor"
      strokeWidth="2.47692"
    />
    <path
      d="M5.8125 2.00092H11.5625V9.18842L8.6875 8.03842L5.8125 9.18842V2.00092Z"
      fill="currentColor"
    />
    <path
      d="M1.5 22.8447V23.5634C1.5 25.1513 2.78718 26.4384 4.375 26.4384H24.5V6.31345L20.4799 1.56158"
      stroke="currentColor"
      strokeWidth="2.47692"
    />
    <path
      d="M5.8125 14.9384L15.875 14.9384"
      stroke="currentColor"
      strokeWidth="2.47692"
    />
    <path
      d="M5.8125 19.251L15.875 19.251"
      stroke="currentColor"
      strokeWidth="2.47692"
    />
  </svg>
);

export { Library };
