const PowerBI = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M30 31.5001L33 27.9999V10.15L30 7H10L7 10.15V27.9999L10 31.5001M13 27.65V35M22.334 22.4L22.334 35M17.667 20.3L17.667 35M27.001 17.1501V35"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { PowerBI };
