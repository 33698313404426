const SwitchPhysical = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M2 5.08835V25.3106L4.88889 28.1995H25.1111L28 25.3106V5.08835L25.1111 2.19946H4.88889L2 5.08835Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M28 21.6995L24.75 18.4495H5.25L2 21.6995"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M6.875 23.3245H9.3125" stroke="currentColor" strokeWidth="2.8" />
    <path d="M11.75 23.3245H14.1875" stroke="currentColor" strokeWidth="2.8" />
    <path d="M16.625 23.3245H19.0625" stroke="currentColor" strokeWidth="2.8" />
    <path d="M21.5 23.3245H23.9375" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { SwitchPhysical };
