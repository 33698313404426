const Copy = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M7.49992 7.50001V3.33334H16.6666V12.5H12.4999M12.4999 7.50001H3.33325V16.6667H12.4999V7.50001Z"
        stroke="#222222"
        strokeWidth="1.6"
      />
    </svg>
  );
};

export { Copy };
