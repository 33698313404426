const VMWareCloudDirector = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M28.9888 28.4395C32.9403 27.9523 36 24.5835 36 20.5C36 16.0817 32.418 12.5 28 12.5C28 8.08171 24.418 4.5 20 4.5C15.582 4.5 12 8.08171 12 12.5C7.58203 12.5 4 16.0817 4 20.5C4 24.9183 7.58203 28.5 12 28.5H12.5M18.4776 23.5496L21.6845 20.3428M18.0834 28.0747L21.2903 31.2681M19 25.5C19 27.7091 17.2091 29.5 15 29.5C12.7909 29.5 11 27.7091 11 25.5C11 23.2909 12.7909 21.5 15 21.5C17.2091 21.5 19 23.2909 19 25.5ZM27 18.5C27 20.1569 25.6569 21.5 24 21.5C22.3431 21.5 21 20.1569 21 18.5C21 16.8431 22.3431 15.5 24 15.5C25.6569 15.5 27 16.8431 27 18.5ZM27 32.5C27 34.1569 25.6569 35.5 24 35.5C22.3431 35.5 21 34.1569 21 32.5C21 30.8431 22.3431 29.5 24 29.5C25.6569 29.5 27 30.8431 27 32.5Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { VMWareCloudDirector };
