const Microphone = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <path
      d="M16.8584 4.89124L26.8584 14.8912"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <circle
      cx="21.8584"
      cy="9.89124"
      r="8"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M13.8584 9.89124L1.97975 25.4476L6.9295 30.3973L22.8584 17.8912"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Microphone };
