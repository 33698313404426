const Serverless = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M17.5 34.5L25.5 20.2636H15.5L24.5 5.5M12.5 12.5L4.5 20.5L12.5 28.5M27.5 12.5L35.5 20.5L27.5 28.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Serverless };
