const OneC = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <rect
        x="2"
        y="2"
        width="26"
        height="26"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M5.25 10.125H7.5C8.05228 10.125 8.5 10.5727 8.5 11.125V21.5"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M20.8728 15C20.8728 12.3076 18.8306 10.125 16.3114 10.125C13.7922 10.125 11.75 12.3076 11.75 15C11.75 17.6924 13.7922 19.875 16.3114 19.875H24.75"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { OneC };
