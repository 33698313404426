const RabbitMQ = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M33 33V18H24V7H18V18H13V7H7V33H33Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M24 23H26V25H24V23Z" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { RabbitMQ };
