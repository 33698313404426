const CloudSaaS = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="32"
    viewBox="0 0 36 32"
    fill="none"
  >
    <circle cx="17.9999" cy="22" r="1.92857" fill="currentColor" />
    <path
      d="M16 14L13.5 14L12.1117 16.4681M20 14L22.5 14L23.908 16.5031M25.7981 19.8633L27 22L25.6036 24.4826M10.2155 19.8391L9 22L10.3734 24.4416M23.6937 27.8779L22.5 30L20 30M12.2934 27.8549L13.5 30L16 30"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M16 14.8154H20" stroke="currentColor" strokeWidth="2.8" />
    <path d="M16 29.185H20" stroke="currentColor" strokeWidth="2.8" />
    <path d="M23 16.8682L25 20.4238" stroke="currentColor" strokeWidth="2.8" />
    <path d="M23 27.6083L25 24.0528" stroke="currentColor" strokeWidth="2.8" />
    <path d="M13 16.8682L11 20.4238" stroke="currentColor" strokeWidth="2.8" />
    <path d="M13 27.6083L11 24.0528" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M11.6 26H10C5.58203 26 2 22.4183 2 18C2 13.5817 5.58203 10 10 10C10 5.58171 13.582 2 18 2C22.418 2 26 5.58171 26 10C30.418 10 34 13.5817 34 18C34 22.4183 30.418 26 26 26H24.4"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { CloudSaaS };
