const Bug = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="30"
    viewBox="0 0 32 30"
    fill="none"
  >
    <path
      d="M10.25 9.50006V22.661L11.75 24.5001H20.75L22.25 22.661V9.50006"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M12.9209 5.07596C12.9209 3.48047 14.2143 2.18707 15.8098 2.18707H16.6902C18.2857 2.18707 19.5791 3.48047 19.5791 5.07596V8.18707H12.9209V5.07596Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M10.25 11.5L5.25 6.64949L5.25 0.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M10.25 17.5L5.25 22.7915L5.25 29.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M9.75 14.5L2.25 14.5L2.25 19.5314"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M22.25 14.5L29.75 14.5L29.75 19.5314"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M22.25 11.5L27.25 6.64949V0.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M22.25 17.5L27.25 22.7915V29.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Bug };
