const GreenplumDatabase = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M34 20C34 27.732 27.732 34 20 34C12.268 34 6 27.732 6 20C6 12.268 12.268 6 20 6C27.732 6 34 12.268 34 20Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M13.8213 13.8213C10.4089 17.2337 10.5405 22.8979 14.1153 26.4727C14.8293 27.1797 16.551 27.6536 17.7259 23.8941C19.1945 19.1947 22.3686 17.1631 26.5214 16.2093C27.7503 15.9271 27.1696 14.8119 26.4727 14.1153C22.8979 10.5405 17.2337 10.4089 13.8213 13.8213Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { GreenplumDatabase };
