const Oracle = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path d="M7 7H33V33H7V7Z" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M12 20C12 17.2386 14.2386 15 17 15H23C25.7614 15 28 17.2386 28 20C28 22.7614 25.7614 25 23 25H17C14.2386 25 12 22.7614 12 20Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Oracle };
