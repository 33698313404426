const Fire = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      className={className}
    >
      <path
        d="M5.05101 12.375C4.85957 12.7059 4.75 13.0902 4.75 13.5C4.75 14.7426 5.75736 15.75 6.99999 15.75C8.24263 15.75 9.24999 14.7426 9.24999 13.5C9.24999 13.0902 9.14042 12.7059 8.94898 12.375"
        stroke="black"
        strokeWidth="1.6"
      />
      <path
        d="M8.94862 12.375L7.00007 9L5.05151 12.375"
        stroke="black"
        strokeWidth="1.6"
      />
      <path
        d="M7.00001 15.75C10.3137 15.75 13 13.0637 13 9.75V9L9.25001 3.75001L7.75001 5.25001L5.50002 2.25002L1 9L1.00003 9.75C1.00003 13.0637 3.68631 15.75 7.00001 15.75Z"
        stroke="black"
        strokeWidth="1.6"
      />
    </svg>
  );
};

export { Fire };
