const ServerDownload = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
    >
      <path
        d="M28 12.3334L26 10.1667H4L2 12.3334V16.6667L4 18.8334H26L28 16.6667V12.3334Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M28 3.66667L26 1.5H4L2 3.66667V8L4 10.1667H26L28 8V3.66667Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M15 14.5H25" stroke="currentColor" strokeWidth="2.8" />
      <path d="M15 5.83325H25" stroke="currentColor" strokeWidth="2.8" />
      <path d="M7 13.1001V15.9001" stroke="currentColor" strokeWidth="2.8" />
      <path d="M7 4.43335V7.23335" stroke="currentColor" strokeWidth="2.8" />
      <path d="M15 24.5L15 21.5" stroke="currentColor" strokeWidth="2.8" />
      <path d="M15 30.5L21 24.5L9 24.5L15 30.5Z" fill="currentColor" />
    </svg>
  );
};

export { ServerDownload };
