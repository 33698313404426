const Satellite = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={className}
    >
      <path
        d="M26.2617 16.296L18.5001 30.5"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M16.5 15.5L5.5 26.5" stroke="currentColor" strokeWidth="2.8" />
      <path
        d="M15.5844 6.24705L1.50014 13.5"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M17.5 4.5L27.5 14.5" stroke="currentColor" strokeWidth="2.8" />
      <circle
        cx="22.5"
        cy="9.5"
        r="8"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Satellite };
