const NetworkCard = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M23 16.8005H27V20.8005H23V16.8005Z" fill="currentColor" />
      <path
        d="M10 33.8005V7.80054H5M19 33.8005V29.8005M15 29.8005V24.8005M25 33.8005V29.8005M31 33.8005V29.8005M10 10.8005H33V29.8005H10V10.8005ZM23 16.8005H27V20.8005H23V16.8005Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { NetworkCard };
