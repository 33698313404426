const Part = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M16 23H22.3137C22.3137 24.0774 22.905 26.2323 25.2702 26.2323C27.6355 26.2323 28.2268 24.0774 28.2268 23H33M16 23V18.087C17.0774 18.087 19.2323 17.4957 19.2323 15.1304C19.2323 12.7652 17.0774 12.1739 16 12.1739L16 7M16 23H17M7 7H33V33H7V7Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Part };
