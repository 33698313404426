const Wallet = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <path
        d="M2 7H28V28H8.5C4.91015 28 2 25.0899 2 21.5V7Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M2 4.5C2 3.11929 3.11929 2 4.5 2H22V7H4.5C3.11929 7 2 5.88071 2 4.5V4.5Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M2 4.4375V11.75" stroke="currentColor" strokeWidth="2.8" />
      <path d="M23.125 16.625V19.425" stroke="currentColor" strokeWidth="2.8" />
    </svg>
  );
};

export { Wallet };
