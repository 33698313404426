const Download = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="27"
    viewBox="0 0 30 27"
    fill="none"
  >
    <path d="M15 12.5L15 0.5" stroke="currentColor" strokeWidth="2.8" />
    <path d="M15 18.5L21 12.5L9 12.5L15 18.5Z" fill="currentColor" />
    <path d="M2 12.5V25.5H28V12.5" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Download };
