const Card = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M7 16H33M17 27H20M22 27H25M27 27H30M9 8L7 10.1538V30L9 32H31L33 30V10L31 8H9Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Card };
