const ArrowsMix = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="28"
      viewBox="0 0 26 28"
      fill="none"
      className={className}
    >
      <path d="M0 22H4.5L16.5 6H21" stroke="currentColor" strokeWidth="2.8" />
      <path d="M0 6H4.5L16.5 22H21" stroke="currentColor" strokeWidth="2.8" />
      <path d="M26 6L20 0V12L26 6Z" fill="currentColor" />
      <path d="M26 22L20 16V28L26 22Z" fill="currentColor" />
    </svg>
  );
};
export { ArrowsMix };
