const S3Compatible = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M6.875 28L2 2H28L23.125 28H6.875Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <rect x="11.77" y="6" width="6" height="6" fill="currentColor" />
    <path
      d="M14.2818 21L8.25825 21C7.61543 21 7.22718 20.2889 7.57479 19.7481L10.5866 15.0632C10.9064 14.5656 11.6336 14.5656 11.9535 15.0632L14.9652 19.7481C15.3129 20.2889 14.9246 21 14.2818 21Z"
      fill="currentColor"
    />
    <circle cx="19.3398" cy="18" r="3" fill="currentColor" />
  </svg>
);

export { S3Compatible };
