const MyOffice = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M25.0995 17.1256C24.5017 15.4837 23.038 13.714 19.9271 13.714C15.2799 13.714 13.8922 17.3931 13.7793 19.2326C12.6497 19.2165 10.3906 19.8716 10.3906 22.6212C10.3906 25.3709 12.3968 26.2101 13.3999 26.286H23.6781L29.6089 20.2007C29.3378 17.4898 26.4901 17.0614 25.0995 17.1256ZM25.0995 17.1256C24.0167 17.2599 22.8625 17.8165 22.0586 18.8502C19.9644 21.5429 22.0793 25.1775 23.9136 26.2699M7 7H33V33H7V7Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { MyOffice };
