const Gateway = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="30"
    viewBox="0 0 32 30"
    fill="none"
  >
    <rect
      x="9.5"
      y="8.5"
      width="13"
      height="13"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M3 21.5V28H29V15" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M29 8.5L29 2L2.99999 2L2.99998 15"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <circle cx="3" cy="15" r="3" fill="currentColor" />
    <circle cx="29" cy="15" r="3" fill="currentColor" />
    <path d="M9.5 15H22.5" stroke="currentColor" strokeWidth="2.8" />
    <path d="M17.625 9.3125V14.1875" stroke="currentColor" strokeWidth="2.8" />
    <path d="M14.375 15L14.375 21.5" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { Gateway };
