const VirtualSwitch = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <rect
        x="2"
        y="2"
        width="26"
        height="26"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M2 10.125L19.875 10.125"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M28 19.875L10.125 19.875"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M25 10L19 16V4L25 10Z" fill="currentColor" />
      <path d="M5 20L11 26V14L5 20Z" fill="currentColor" />
    </svg>
  );
};

export { VirtualSwitch };
