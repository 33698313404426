const ObjectStorage = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <rect
      x="2"
      y="2"
      width="26"
      height="26"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <rect x="11.9019" y="7.68555" width="6" height="6" fill="currentColor" />
    <path
      d="M13.4136 22.3145L7.39008 22.3145C6.74726 22.3145 6.35902 21.6033 6.70663 21.0626L9.7184 16.3776C10.0382 15.8801 10.7655 15.8801 11.0853 16.3776L14.0971 21.0626C14.4447 21.6033 14.0564 22.3145 13.4136 22.3145Z"
      fill="currentColor"
    />
    <circle cx="19.6084" cy="19.3145" r="3" fill="currentColor" />
  </svg>
);
export { ObjectStorage };
