const Unlocked = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="32"
    viewBox="0 0 28 32"
    fill="none"
  >
    <rect
      x="2"
      y="10"
      width="24"
      height="20"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M23 5.2V5.2C23 3.43269 21.5673 2 19.8 2H9.42857C7.53502 2 6 3.53502 6 5.42857V10"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <circle cx="14" cy="20" r="3" fill="currentColor" />
  </svg>
);

export { Unlocked };
