const Airflow = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M20 19.8988C21.2583 24.9019 23.9322 25.6316 25.1118 25.371L29.8304 20.2896C33.6053 16.85 33.2383 9.99669 32.5829 7L25.1118 14.6586M20.1012 20C15.0981 21.1534 14.3684 23.6045 14.629 24.6858L19.7104 29.0112C23.15 32.4715 30.0033 32.1351 33 31.5343L25.642 24.9547M20 20.1012C18.7417 15.0981 16.0678 14.3684 14.8882 14.629L10.1696 19.7104C6.39473 23.15 6.76174 30.0033 7.41709 33L15.0858 25.1388M19.8988 20C24.9019 18.7417 25.6316 16.0678 25.371 14.8882L20.2896 10.1696C16.85 6.39474 9.99669 6.76174 7 7.4171L14.6586 14.8882"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Airflow };
