const Flame = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="33"
      viewBox="0 0 30 33"
      fill="none"
      className={className}
    >
      <path
        d="M15 31.5C22.1797 31.5 28 25.7294 28 18.6111V17L19.875 5.72222L16.625 8.94444L11.75 2.5L2 17L2.00006 18.6111C2.00006 25.7294 7.82034 31.5 15 31.5Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M10.6522 23.8125C10.2374 24.5295 10 25.3621 10 26.25C10 28.9424 12.1826 31.125 14.875 31.125C17.5674 31.125 19.75 28.9424 19.75 26.25C19.75 25.3621 19.5126 24.5295 19.0978 23.8125"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M19.0966 23.8125L14.8747 16.5L10.6528 23.8125"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Flame };
