const Gpu = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="29"
    viewBox="0 0 30 29"
    fill="none"
  >
    <rect
      x="4.6665"
      y="5.08838"
      width="23.3333"
      height="18.6667"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M4.66667 28.4217V1.97729H0"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M14 28.4217V23.755" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M9.3335 23.755L9.3335 19.0884"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M20.2222 28.4217V23.755" stroke="currentColor" strokeWidth="2.8" />
    <path d="M26.4443 28.4217V23.755" stroke="currentColor" strokeWidth="2.8" />
    <rect x="18" y="9.19946" width="6" height="6" fill="currentColor" />
  </svg>
);

export { Gpu };
