const ObjectsUnion = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="34"
      viewBox="0 0 33 34"
      fill="none"
      className={className}
    >
      <path
        d="M22.047 3.49997L29.6714 11.1244L19.1878 13.9835L22.047 3.49997Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <circle
        cx="6.97949"
        cy="13.1248"
        r="4.70263"
        transform="rotate(45 6.97949 13.1248)"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <rect
        x="21.7041"
        y="24.5"
        width="8"
        height="8"
        transform="rotate(90 21.7041 24.5)"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M4.92567 16.8092C4.9018 17.1229 4.88965 17.4399 4.88965 17.7598C4.88965 23.0814 8.25314 27.6173 12.9702 29.3578M9.16758 8.40876C11.3342 6.53487 14.1588 5.40131 17.2481 5.40131C18.752 5.40131 20.1931 5.66991 21.5261 6.16176M27.9533 11.5806C29.0048 13.3983 29.6066 15.5088 29.6066 17.7598C29.6066 23.0814 26.2431 27.6173 21.5261 29.3578"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { ObjectsUnion };
