const EnvelopeOpen = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="32"
    viewBox="0 0 30 32"
    fill="none"
  >
    <path
      d="M1.99756 10.0746V29.8125H27.9976V10.0746"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M1.99756 10.3125L14.9976 19.25L27.9976 10.3125"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M1.37695 11.2412L14.9974 2.18745L28.6228 11.2412"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M19.8726 16.8125L27.9976 24.9375"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M10.9351 16.8125L1.99756 25.75"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { EnvelopeOpen };
