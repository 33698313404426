const Ssd = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <rect
        x="2"
        y="2"
        width="26"
        height="26"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M2 6.875H28" stroke="currentColor" strokeWidth="2.8" />
      <path
        d="M10.125 2L10.125 6.875"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M19.875 2V6.875" stroke="currentColor" strokeWidth="2.8" />
      <rect
        x="10"
        y="12.5126"
        width="10"
        height="10"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Ssd };
