const User = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="14" stroke="#222222" strokeWidth="2.8" />
      <circle
        cx="5"
        cy="5"
        r="5"
        transform="matrix(1 0 0 -1 15 22)"
        stroke="#222222"
        strokeWidth="2.8"
      />
      <path
        d="M10 30L14.6154 26H25.3846L30 30"
        stroke="#222222"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { User };
