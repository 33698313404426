const Superset = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M16.5844 14.944C15.1833 13.9566 13.4034 13.2952 11.2262 13.5578C8.08461 13.9368 5.5 16.4445 5.5 20.0053C5.5 23.5662 8.08461 26.0739 11.2262 26.4529C13.5422 26.7322 15.5106 25.745 17.2073 24.1599C18.9039 22.5748 19.7308 20.8541 20.9604 18.8468C23.3224 14.9904 25.7475 13.6157 28.1673 13.5578C30.587 13.5 34.2548 15.0376 34.4894 19.4222C34.6479 22.3832 33.0486 26.2248 28.1673 26.4529C27.1392 26.5757 24.8621 26.4468 23.188 25.1302"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Superset };
