const MLSpace = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20.1333 29.2V12.778V10.6666H17.7222V11.0576C17.6444 11.5268 17.2556 11.8396 16.8667 11.8396H14.9222L12.6667 18.0174L10.4111 11.9178H8.54446C8.00001 11.9178 7.61113 11.4486 7.61113 10.9012V10.6666H5.20001V29.2H7.61113V12.778H7.92223C8.23335 12.778 8.3889 12.8562 8.54446 13.0908L11.8111 21.8492H13.6778L16.8667 13.0908C17.0222 12.8562 17.1 12.778 17.4889 12.778H17.7222V29.2H20.1333Z"
        fill="currentColor"
      />
      <path
        d="M32.4666 25.9156C32.4666 26.3848 32.0778 26.7758 31.5333 26.7758H26.8667V13.0908H29.5111V10.6666H21.7333V13.0908H24.3777V26.7758H21.7333V29.2H33.6333V28.8871C33.6333 28.418 34.0222 27.9488 34.5667 27.9488H34.8V21.38H32.3889V25.9156H32.4666Z"
        fill="currentColor"
      />
    </svg>
  );
};

export { MLSpace };
