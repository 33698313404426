const Diamond = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      width="34"
      height="31"
      viewBox="0 0 34 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2 11.4L16.89 28.5L32 11.4L26.3521 1.5H7.50147L2 11.4Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M14.2727 1.5L10.5 10.8462L16 27.4615M19.7273 1.5L23.5 10.8462L18 26.9423"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M2 11.0195H31.5"
        stroke="currentColor"
        strokeWidth="2.8"
        strokeLinejoin="bevel"
      />
    </svg>
  );
};

export { Diamond };
