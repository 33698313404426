const Balance = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="29"
    viewBox="0 0 34 29"
    fill="none"
  >
    <path d="M17 26.5V0.5" stroke="currentColor" strokeWidth="2.8" />
    <path d="M11 27.5L23 27.5" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M6 12.5V4.5L28 4.5L28 12.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M6 10.5L0 16.5L12 16.5L6 10.5Z" fill="currentColor" />
    <path d="M28 10.5L22 16.5L34 16.5L28 10.5Z" fill="currentColor" />
  </svg>
);

export { Balance };
