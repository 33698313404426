const HardDrive = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="30"
    viewBox="0 0 29 30"
    fill="none"
  >
    <rect
      x="1.56885"
      y="2.19934"
      width="26"
      height="26.0002"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M11.1651 21.8668C12.0433 22.2112 12.9996 22.4002 14 22.4002C18.291 22.4002 21.7695 18.9217 21.7695 14.6307C21.7695 10.3396 18.291 6.86108 14 6.86108C9.709 6.86108 6.23047 10.3396 6.23047 14.6307C6.23047 15.6141 6.41319 16.5549 6.74654 17.4209L6.99889 18.0482"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M1.56887 21.3248L13.2231 15.8862L7.7845 27.5405"
      stroke="currentColor"
      strokeWidth="2.8"
      strokeLinejoin="bevel"
    />
  </svg>
);

export { HardDrive };
