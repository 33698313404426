const ServerGlacierDeep = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M8.65186 23.2867L6.65186 25.4533V29.7867L8.65186 31.9533H19.6519M8.65186 23.2867L6.65186 21.1201V16.7867L8.65186 14.6201M8.65186 23.2867L19.6519 23.2867M8.65186 14.6201H19.6519M8.65186 14.6201L6.65186 12.4534V8.12004L8.65186 5.95337H30.6519L32.6519 8.12004M11.6519 26.22V29.02M11.6519 17.5534V20.3534M11.6519 8.88672V11.6867"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M35.3481 31.9221H21.3481"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M31.8481 25.86L24.8481 37.9844"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M24.8481 25.9223L31.8481 38.0466"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M35.3481 17.1622H21.3481"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M31.8481 11.1L24.8481 23.2244"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M24.8481 11.1623L31.8481 23.2866"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { ServerGlacierDeep };
