const SchoolBuilding = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <rect
      x="16.3916"
      y="1.85968"
      width="4"
      height="4"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M15 16.1403V18.9403" stroke="currentColor" strokeWidth="2.8" />
    <path d="M15 22.1403V24.9403" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M2 14.4575H8.96429L15 9.14032L21.0357 14.4575H28V28.1403H2V14.4575Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { SchoolBuilding };
