const GlobalProtect = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
  >
    <path
      d="M20.9443 15.9443C20.9443 8.21235 18.7058 1.94434 15.9443 1.94434C13.1829 1.94434 10.9443 8.21235 10.9443 15.9443C10.9443 23.6763 13.1829 29.9443 15.9443 29.9443"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M3.5 20.611L15.9443 20.611"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M3.5 11.2776L28.3889 11.2777"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M29.9443 15.9443C29.9443 8.21235 23.6763 1.94434 15.9443 1.94434C8.21235 1.94434 1.94434 8.21235 1.94434 15.9443C1.94434 23.6763 8.21235 29.9443 15.9443 29.9443"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M28.9303 30.5905L26 32.0557L23.0697 30.5905C21.1543 29.6328 19.9443 27.6751 19.9443 25.5336V19.9443H32.0557V25.5336C32.0557 27.6751 30.8457 29.6328 28.9303 30.5905Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { GlobalProtect };
