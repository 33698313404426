const Scalable = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M18 7H10L7 10V30L10 33H30L33 30V21.625M23 7H33M33 7V17M33 7L20 20"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Scalable };
