const TrainingCourse = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="34"
      viewBox="0 0 30 34"
      fill="none"
      className={className}
    >
      <path d="M6 8H2V26H28V8H24" stroke="currentColor" strokeWidth="2.8" />
      <path d="M15 26L15 31" stroke="currentColor" strokeWidth="2.8" />
      <path d="M7 32H23" stroke="currentColor" strokeWidth="2.8" />
      <path
        d="M15 2L5 8L15 14L25 8L15 2Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M8 10V16.4472L15 20L22 16.4472V10"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { TrainingCourse };
