const Kibana = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path d="M10 8H30L10 29V8Z" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M10 34.0001V18C26.1674 15.9791 31.3048 27.7689 31.6417 34.0001H10Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Kibana };
