const Present = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path d="M4 13.5V28.5H26V13.5" stroke="currentColor" strokeWidth="2.8" />
    <path d="M2 6.5H28V13.5H2V6.5Z" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M15 7V28"
      stroke="currentColor"
      strokeWidth="2.8"
      strokeLinejoin="bevel"
    />
    <path
      d="M15 6.5L20 1.5"
      stroke="currentColor"
      strokeWidth="2.8"
      strokeLinejoin="bevel"
    />
    <path
      d="M15 6.5L10 1.5"
      stroke="currentColor"
      strokeWidth="2.8"
      strokeLinejoin="bevel"
    />
  </svg>
);

export { Present };
