const CloudUpload = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="28"
      viewBox="0 0 36 28"
      fill="none"
      className={className}
    >
      <path d="M18 18.2L18 27.8" stroke="currentColor" strokeWidth="2.8" />
      <path d="M18 12.2L24 18.2L12 18.2L18 12.2Z" fill="currentColor" />
      <path
        d="M10 26.2C5.58203 26.2 2 22.6183 2 18.2C2 13.7817 5.58203 10.2 10 10.2C10 5.78171 13.582 2.2 18 2.2C22.418 2.2 26 5.78171 26 10.2C30.418 10.2 34 13.7817 34 18.2C34 22.6183 30.418 26.2 26 26.2"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { CloudUpload };
