const Heart = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M13 8C9.13401 8 6 11.148 6 15.0312C6 16.9044 6.72919 18.6064 7.91799 19.8666L20 33L32.0753 19.8737C33.2681 18.6128 34 16.9079 34 15.0312C34 11.148 30.866 8 27 8C23.951 8 21.3611 9.93827 20.401 12.6708H19.5958C18.6301 9.9486 16.0416 8 13 8Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Heart };
