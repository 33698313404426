import React from 'react';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { Button } from 'src/uikit/Button';

export function Submit({
  children,
  disabled,
}: {
  children: string;
  disabled?: boolean;
}): JSX.Element {
  const { funnelFormSubmit } = useAnalytics();
  return (
    <Button
      onClick={funnelFormSubmit}
      fullWidth
      isDisabled={disabled}
      type="submit"
      analyticsOptions={{
        action: 'click',
        clickZone: 'body',
        clickElement: 'button',
        clickContent: children,
        uniqueId: 'submit-button',
        transitionType: 'inside-link',
      }}
    >
      {children}
    </Button>
  );
}
