const Home = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33 33V14.6471L20 7L7 14.6471V33H11.875H28.125H33Z"
        stroke="#222222"
        strokeWidth="2.8"
      />
      <path
        d="M11.875 21.4932L28.125 21.4932"
        stroke="#222222"
        strokeWidth="2.8"
      />
      <path
        d="M11.875 26.3682L28.125 26.3682"
        stroke="#222222"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Home };
