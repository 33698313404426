const Headphones = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M2 25V13.9259C2 7.33942 7.37258 2 14 2C20.6274 2 26 7.33942 26 13.9259V25"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <rect
      x="2"
      y="17"
      width="6"
      height="9"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <rect
      x="20"
      y="17"
      width="6"
      height="9"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Headphones };
