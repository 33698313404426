const OperationSystem = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="35"
    viewBox="0 0 30 35"
    fill="none"
  >
    <path
      d="M7.61812 7.97522H2V25.9752H28V7.97522H22.1581"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M15 26.9752V33.9752" stroke="currentColor" strokeWidth="2.8" />
    <path d="M7 33L23 33" stroke="currentColor" strokeWidth="2.8" />
    <circle cx="14.9999" cy="10.0249" r="1.92857" fill="currentColor" />
    <path
      d="M13 2.0249L10.5 2.0249L9.11167 4.49304M17 2.0249L19.5 2.0249L20.908 4.52801M22.7981 7.88819L24 10.0249L22.6036 12.5075M7.21551 7.864L6 10.0249L7.37338 12.4665M20.6937 15.9028L19.5 18.0249L17 18.0249M9.29337 15.8798L10.5 18.0249L13 18.0249"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M13 2.84033H17" stroke="currentColor" strokeWidth="2.8" />
    <path d="M13 17.2098H17" stroke="currentColor" strokeWidth="2.8" />
    <path d="M20 4.89307L22 8.44862" stroke="currentColor" strokeWidth="2.8" />
    <path d="M20 15.6332L22 12.0776" stroke="currentColor" strokeWidth="2.8" />
    <path d="M10 4.89307L8 8.44862" stroke="currentColor" strokeWidth="2.8" />
    <path d="M10 15.6332L8 12.0776" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { OperationSystem };
