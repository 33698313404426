const ExternalIP = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
  >
    <path
      d="M8.73917 25.0328L7.52994 25.7383L7.64861 25.9417L7.82727 26.0951L8.73917 25.0328ZM31.2608 25.0328L32.1727 26.0951L32.3514 25.9417L32.4701 25.7383L31.2608 25.0328ZM20 34.6995L19.0881 35.7617L20 36.5445L20.9119 35.7617L20 34.6995ZM17.4 13C17.4 12.2268 16.7732 11.6 16 11.6C15.2268 11.6 14.6 12.2268 14.6 13H17.4ZM14.6 24C14.6 24.7732 15.2268 25.4 16 25.4C16.7732 25.4 17.4 24.7732 17.4 24H14.6ZM20.5455 13V11.6C19.7723 11.6 19.1455 12.2268 19.1455 13H20.5455ZM19.1455 24C19.1455 24.7732 19.7723 25.4 20.5455 25.4C21.3187 25.4 21.9455 24.7732 21.9455 24H19.1455ZM8.4 18.5884C8.4 12.2545 13.5821 7.09946 20 7.09946V4.29946C12.0585 4.29946 5.6 10.6855 5.6 18.5884H8.4ZM20 7.09946C26.4179 7.09946 31.6 12.2545 31.6 18.5884H34.4C34.4 10.6855 27.9415 4.29946 20 4.29946V7.09946ZM9.94839 24.3273C8.96358 22.6394 8.4 20.6809 8.4 18.5884H5.6C5.6 21.191 6.30254 23.6347 7.52994 25.7383L9.94839 24.3273ZM31.6 18.5884C31.6 20.6809 31.0364 22.6394 30.0516 24.3273L32.4701 25.7383C33.6975 23.6347 34.4 21.191 34.4 18.5884H31.6ZM7.82727 26.0951L19.0881 35.7617L20.9119 33.6372L9.65107 23.9705L7.82727 26.0951ZM20.9119 35.7617L32.1727 26.0951L30.3489 23.9705L19.0881 33.6372L20.9119 35.7617ZM20.5455 21.0046H23.2727V18.2046H20.5455V21.0046ZM23.2727 21.0046C23.9241 21.0046 24.911 20.8401 25.7782 20.2352C26.7236 19.5757 27.4 18.4747 27.4 16.9193H24.6C24.6 17.2715 24.5287 17.4883 24.4598 17.6207C24.3907 17.7535 24.2962 17.8551 24.1763 17.9387C23.9072 18.1264 23.5305 18.2046 23.2727 18.2046V21.0046ZM27.4 16.9193V15.8108H24.6V16.9193H27.4ZM27.4 15.8108C27.4 14.6812 27.1197 13.573 26.3282 12.7484C25.5268 11.9136 24.4242 11.6 23.2727 11.6V14.4C23.9395 14.4 24.2005 14.5752 24.3082 14.6874C24.4257 14.8098 24.6 15.1071 24.6 15.8108H27.4ZM23.2727 11.6H20.5455V14.4H23.2727V11.6ZM19.1455 19.6046V24H21.9455V19.6046H19.1455ZM14.6 13V24H17.4V13H14.6ZM19.1455 13V19.6046H21.9455V13H19.1455Z"
      fill="currentColor"
    />
  </svg>
);

export { ExternalIP };
