const Discount = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="20" cy="20" r="14" stroke="currentColor" strokeWidth="2.8" />
      <path d="M11 29L30 10" stroke="currentColor" strokeWidth="2.8" />
      <circle cx="15" cy="15" r="3" stroke="currentColor" strokeWidth="2.8" />
      <circle cx="25" cy="25" r="3" stroke="currentColor" strokeWidth="2.8" />
    </svg>
  );
};

export { Discount };
