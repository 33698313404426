const CpuSocket = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <rect
      x="8.5"
      y="8.69946"
      width="13"
      height="13"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <rect
      x="2"
      y="2.19946"
      width="26"
      height="26"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M12.9126 11.5099V14.3099"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M12.9126 16.0891V18.8891"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M17.5503 11.5099V14.3099"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M17.5503 16.0891V18.8891"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { CpuSocket };
