const Snapshot = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M8.5 2H2V8.5M21.5 2H28V8.5M28 21.5V28H21.5M2 21.5V28H8.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <rect
      x="10"
      y="10"
      width="10"
      height="10"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Snapshot };
