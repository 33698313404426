const Key = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
  >
    <path d="M10.5 28.5L8 26" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M14.2173 24.7825L11.7173 22.2825"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M13.2173 15.2825L2.86917 25.4737L3.11066 30.8891L8.52603 31.1306L18.7173 20.7825"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <circle
      cx="23.2822"
      cy="10.2825"
      r="3"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <circle
      cx="22.2173"
      cy="11.7825"
      r="9.5"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Key };
