const CloudDownload = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="28"
      viewBox="0 0 36 28"
      fill="none"
      className={className}
    >
      <path
        d="M10 26C5.58203 26 2 22.4183 2 18C2 13.5817 5.58203 10 10 10C10 5.58171 13.582 2 18 2C22.418 2 26 5.58171 26 10C30.418 10 34 13.5817 34 18C34 22.4183 30.418 26 26 26"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M18 22V13.2" stroke="currentColor" strokeWidth="2.8" />
      <path d="M18 28L24 22L12 22L18 28Z" fill="currentColor" />
    </svg>
  );
};

export { CloudDownload };
