const SOBR = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <rect
      x="2"
      y="2"
      width="8"
      height="8"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <rect
      x="20"
      y="2"
      width="8"
      height="8"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <rect
      x="2"
      y="20"
      width="8"
      height="8"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M11 6L19 6" stroke="currentColor" strokeWidth="2.8" />
    <path d="M10 24H15V7" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { SOBR };
