const Hyperlink = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M16.9696 14.207L13.0853 10.3226L1 22.408L7.59197 29.0001L13.6346 22.9574"
      stroke="currentColor"
      strokeWidth="2.8"
      strokeLinejoin="bevel"
    />
    <path
      d="M13.4302 16.193L16.9147 19.6775L29 7.59208L22.408 1.00003L16.3654 7.04274"
      stroke="currentColor"
      strokeWidth="2.8"
      strokeLinejoin="bevel"
    />
  </svg>
);

export { Hyperlink };
