const Basealt = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M21 20C21 20.5523 20.5523 21 20 21C19.4477 21 19 20.5523 19 20C19 19.4477 19.4477 19 20 19C20.5523 19 21 19.4477 21 20Z"
      fill="currentColor"
    />
    <path
      d="M7 6.62476H33V32.6248H7V6.62476Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M21 20C21 20.5523 20.5523 21 20 21C19.4477 21 19 20.5523 19 20C19 19.4477 19.4477 19 20 19C20.5523 19 21 19.4477 21 20Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Basealt };
