const Hypervisor = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M30 23.1578L27.8462 20.8245H4.15385L2 23.1578V27.8245L4.15385 30.1578H27.8462L30 27.8245V23.1578Z"
        stroke="currentColor"
        strokeWidth="3.01538"
      />
      <path d="M16 25.4911H26.7692" stroke="currentColor" strokeWidth="2.8" />
      <path
        d="M7.38477 24.0911V26.8911"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <circle cx="16" cy="13" r="3" fill="currentColor" />
      <path
        d="M13.3676 2.24109L10.077 2.24109L8.24959 5.48975M18.6325 2.24109L21.9231 2.24109L23.7764 5.53578M26.2642 9.95858L27.8462 12.771L26.0081 16.0387M5.75379 9.92673L4.15388 12.771L5.96158 15.9847"
        stroke="currentColor"
        strokeWidth="3.01538"
      />
      <path
        d="M13.3672 3.31433H18.6321"
        stroke="currentColor"
        strokeWidth="3.01538"
      />
      <path
        d="M22.5811 6.01636L25.2135 10.6963"
        stroke="currentColor"
        strokeWidth="3.01538"
      />
      <path
        d="M21.9978 20.7028L25.2136 15.4725"
        stroke="currentColor"
        strokeWidth="3.01538"
      />
      <path
        d="M9.41943 6.01636L6.78696 10.6963"
        stroke="currentColor"
        strokeWidth="3.01538"
      />
      <path
        d="M9.78824 20.7317L6.78716 15.4732"
        stroke="currentColor"
        strokeWidth="3.01538"
      />
    </svg>
  );
};

export { Hypervisor };
