const Alert = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M20 12V22M20 24.875V27.675M7 7H33V33H7V7Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Alert };
