const FaceId = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <path
        d="M8.5 2H2V8.5M21.5 2H28V8.5M28 21.5V28H21.5M2 21.5V28H8.5"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <circle
        cx="4.48276"
        cy="4.48276"
        r="4.48276"
        transform="matrix(1 0 0 -1 10.9653 15.4483)"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M6.48291 23.5173L10.182 19.9311H20.7149L24.4139 23.5173"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { FaceId };
