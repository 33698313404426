const IP = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
  >
    <path
      d="M17.4 15C17.4 14.2268 16.7732 13.6 16 13.6C15.2268 13.6 14.6 14.2268 14.6 15H17.4ZM14.6 26C14.6 26.7732 15.2268 27.4 16 27.4C16.7732 27.4 17.4 26.7732 17.4 26H14.6ZM20.5455 15V13.6C19.7723 13.6 19.1455 14.2268 19.1455 15H20.5455ZM19.1455 26C19.1455 26.7732 19.7723 27.4 20.5455 27.4C21.3187 27.4 21.9455 26.7732 21.9455 26H19.1455ZM32.6 20.1995C32.6 27.1583 26.9588 32.7995 20 32.7995V35.5995C28.5052 35.5995 35.4 28.7046 35.4 20.1995H32.6ZM20 32.7995C13.0412 32.7995 7.4 27.1583 7.4 20.1995H4.6C4.6 28.7046 11.4948 35.5995 20 35.5995V32.7995ZM7.4 20.1995C7.4 13.2407 13.0412 7.59946 20 7.59946V4.79946C11.4948 4.79946 4.6 11.6943 4.6 20.1995H7.4ZM20 7.59946C26.9588 7.59946 32.6 13.2407 32.6 20.1995H35.4C35.4 11.6943 28.5052 4.79946 20 4.79946V7.59946ZM20.5455 23.0046H23.2727V20.2046H20.5455V23.0046ZM23.2727 23.0046C23.9241 23.0046 24.911 22.8401 25.7782 22.2352C26.7236 21.5757 27.4 20.4747 27.4 18.9193H24.6C24.6 19.2715 24.5287 19.4883 24.4598 19.6207C24.3907 19.7535 24.2962 19.8551 24.1763 19.9387C23.9072 20.1264 23.5305 20.2046 23.2727 20.2046V23.0046ZM27.4 18.9193V17.8108H24.6V18.9193H27.4ZM27.4 17.8108C27.4 16.6812 27.1197 15.573 26.3282 14.7484C25.5268 13.9136 24.4242 13.6 23.2727 13.6V16.4C23.9395 16.4 24.2005 16.5752 24.3082 16.6874C24.4257 16.8098 24.6 17.1071 24.6 17.8108H27.4ZM23.2727 13.6H20.5455V16.4H23.2727V13.6ZM19.1455 21.6046V26H21.9455V21.6046H19.1455ZM14.6 15V26H17.4V15H14.6ZM19.1455 15V21.6046H21.9455V15H19.1455Z"
      fill="currentColor"
    />
  </svg>
);

export { IP };
