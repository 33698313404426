const Microphone2 = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M20 28V35M25 35H15M30 25V28H10V25M13 11H18M13 17H18M22 11H27M22 17H27.5M15 5L13 7V21L15 23H25L27 21V7L25 5H15Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Microphone2 };
