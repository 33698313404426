const EventSource = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <ellipse
      cx="16"
      cy="16"
      rx="14"
      ry="13.9999"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <mask id="path-2-inside-1_1600_48701" fill="white">
      <path d="M16.0508 16.0507L22.0508 22.0507H16.0508L10.0508 22.0507L16.0508 16.0507Z" />
    </mask>
    <path
      d="M16.0508 16.0507L22.0508 22.0507H16.0508L10.0508 22.0507L16.0508 16.0507Z"
      fill="currentColor"
    />
    <path
      d="M16.0508 16.0507L18.0307 14.0708L16.0508 12.0909L14.0709 14.0708L16.0508 16.0507ZM22.0508 22.0507V24.8507H28.8106L24.0307 20.0708L22.0508 22.0507ZM10.0508 22.0507L8.07088 20.0708L3.29098 24.8507H10.0508L10.0508 22.0507ZM16.0508 22.0507L16.0508 19.2507L16.0508 22.0507ZM14.0709 18.0306L20.0709 24.0306L24.0307 20.0708L18.0307 14.0708L14.0709 18.0306ZM12.0307 24.0306L18.0307 18.0306L14.0709 14.0708L8.07088 20.0708L12.0307 24.0306ZM22.0508 19.2507L16.0508 19.2507L16.0508 24.8507H22.0508V19.2507ZM16.0508 19.2507L10.0508 19.2507L10.0508 24.8507L16.0508 24.8507L16.0508 19.2507Z"
      fill="currentColor"
      mask="url(#path-2-inside-1_1600_48701)"
    />
    <path
      d="M16.0508 21.0507V29.0507"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8351 8.28858C13.8275 7.87706 14.9145 7.65063 16.0509 7.65063C17.1873 7.65063 18.2743 7.87706 19.2667 8.28858L18.1941 10.875C17.5358 10.602 16.8128 10.4506 16.0509 10.4506C15.289 10.4506 14.566 10.602 13.9076 10.875L12.8351 8.28858ZM23.8129 12.8348C24.2244 13.8272 24.4509 14.9142 24.4509 16.0506C24.4509 17.187 24.2244 18.2741 23.8129 19.2664L21.2265 18.1939C21.4995 17.5355 21.6509 16.8125 21.6509 16.0506C21.6509 15.2887 21.4995 14.5657 21.2265 13.9074L23.8129 12.8348ZM7.65088 16.0506C7.65088 14.9142 7.87731 13.8272 8.28883 12.8348L10.8753 13.9074C10.6023 14.5657 10.4509 15.2887 10.4509 16.0506C10.4509 16.8125 10.6023 17.5355 10.8753 18.1939L8.28883 19.2664C7.87731 18.2741 7.65088 17.187 7.65088 16.0506Z"
      fill="currentColor"
    />
  </svg>
);

export { EventSource };
