const Etc = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path d="M8.5 13.375V16.175" stroke="currentColor" strokeWidth="2.8" />
    <path d="M15.4062 13.375V16.175" stroke="currentColor" strokeWidth="2.8" />
    <path d="M22.3125 13.375V16.175" stroke="currentColor" strokeWidth="2.8" />
    <rect
      x="2"
      y="2"
      width="26"
      height="26"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Etc };
