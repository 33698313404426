const Snowflake = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M14.0905 10.0102L25.603 29.9504M15.6619 4.24683L14.109 10.0424L8.31348 8.48947M24.0305 35.712L25.5834 29.9164L31.379 31.4693"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M25.5965 10.0514L14.084 29.9916M31.3735 8.5306L25.5779 10.0835L24.025 4.28796M8.30811 31.5106L14.1037 29.9576L15.6566 35.7532"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M31.5116 20.0199H8.48659M35.7171 24.2625L31.4745 20.0199L35.7171 15.7772M4.2832 15.7773L8.52584 20.0199L4.2832 24.2626"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Snowflake };
