const MariaDB = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M7.98277 26.5117C8.58247 26.0483 11.8147 24.3457 11.8147 20.8459M11.8147 20.8459C10.0843 22.3551 7.81439 21.8458 6 21.4582M11.8147 20.8459C12.324 19.6311 13.0335 16.8496 16.1474 16.2298C20.0397 15.4552 23.0471 13.5043 24.6252 11.0489C26.2034 8.59362 26.4484 5.5514 29.8063 5.39699C30.9256 5.34552 33.2769 3.52468 33.3021 4.11913C33.5223 4.90326 34.5801 7.8108 31.8448 9.4258C30.7507 10.0718 30.0297 10.7006 29.8078 14.285C29.7155 15.7758 28.7614 18.2974 27.1661 19.4361C25.6535 20.5177 23.8199 22.1333 19.7765 21.4582C17.5076 20.8285 13.8289 19.6078 11.8147 20.8459ZM20.5986 9.23546C13.4189 9.23546 7.59857 12.1456 7.59857 15.7355V18.3911M34 22C34 25.5899 27.7783 28.7355 20.5986 28.7355C17.4782 28.7355 14.6146 28.1858 12.374 27.2695M7.59857 28.7355C7.59857 32.3253 13.4189 35.2355 20.5986 35.2355C27.7783 35.2355 34 32.3253 34 28.7355V16M23.3704 21.4079C23.685 22.5985 23.7064 24.3267 22.5996 25.9258"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { MariaDB };
