const CloudPuzzle = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="28"
      viewBox="0 0 36 28"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 10C26 5.58171 22.418 2 18 2C13.582 2 10 5.58171 10 10C5.58203 10 2 13.5817 2 18C2 22.4183 5.58203 26 10 26H26C30.418 26 34 22.4183 34 18C34 13.5817 30.418 10 26 10Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M14.5996 18.7677H21.9133C21.9133 19.8451 22.5046 22 24.8699 22C27.2351 22 27.8264 19.8451 27.8264 18.7677H33.0003"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M16 20L16 14.087C17.0774 14.087 19.2323 13.4957 19.2323 11.1304C19.2323 8.76522 17.0774 8.17391 16 8.17391L16 3"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { CloudPuzzle };
