const ApacheIgnite = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={className}
    >
      <path
        d="M16.2321 10.4108C16.2321 10.4108 3.77514 18.0176 15.2981 25.4215C17.5145 26.8634 25.5541 30.8729 25.3248 34.5826C25.3248 34.5826 27.1554 31.987 27.1016 29.0178"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M27.0693 18.6938C21.8267 13.2071 20.3672 9.81343 22.6189 5.41736C22.6189 5.41736 6.97983 11.4309 22.4249 22.1051C27.949 25.9206 27.0341 29.8243 27.0341 29.9654C27.8961 29.0688 32.3113 24.1797 27.0693 18.6938Z"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M25.0551 34.5825C23.4369 32.5432 17.2271 31.8929 16.382 31.7871C7.79782 30.4327 12.1601 23.8209 12.1601 23.8209H12.1608"
        stroke="#222222"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { ApacheIgnite };
