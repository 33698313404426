const FlashDrive = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <path
      d="M15.3268 8.03329L19.0307 7.96595L28.1221 17.0573L28.0547 20.7612L15.39 33.4892L11.6862 33.5565L2.59478 24.4651L2.66212 20.7612L15.3268 8.03329Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M28.3051 16.0604L33.2548 11.1106L24.7695 2.62537L19.8198 7.57511"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M8.74516 23.6254L12.7451 27.6254"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { FlashDrive };
