const Redis = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M18 25.5C18 26.8807 16.8807 28 15.5 28C14.1193 28 13 26.8807 13 25.5C13 24.1193 14.1193 23 15.5 23C16.8807 23 18 24.1193 18 25.5Z"
      fill="currentColor"
    />
    <path d="M25 24L23 27.5H27L25 24Z" fill="currentColor" />
    <path d="M7 7H33V33H7V7Z" stroke="currentColor" strokeWidth="2.8" />
    <path
      d="M18 25.5C18 26.8807 16.8807 28 15.5 28C14.1193 28 13 26.8807 13 25.5C13 24.1193 14.1193 23 15.5 23C16.8807 23 18 24.1193 18 25.5Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M15.5 13L16.2788 14.4281L17.8776 14.7275L16.7601 15.9094L16.9695 17.5225L15.5 16.825L14.0305 17.5225L14.2399 15.9094L13.1224 14.7275L14.7212 14.4281L15.5 13Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M25 24L23 27.5H27L25 24Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Redis };
