const SettingsSlider = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="32"
      viewBox="0 0 28 32"
      fill="none"
    >
      <path
        d="M0 5.5H17.5M28 5.5H24.5"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M0 16H3.5M28 16H10.5" stroke="currentColor" strokeWidth="2.8" />
      <path
        d="M0 26.5H17.5M28 26.5H24.5"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <circle
        cx="21"
        cy="5.5"
        r="3.5"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <circle cx="7" cy="16" r="3.5" stroke="currentColor" strokeWidth="2.8" />
      <circle
        cx="21"
        cy="26.5"
        r="3.5"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { SettingsSlider };
