const Locked = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="32"
    viewBox="0 0 28 32"
    fill="none"
  >
    <rect
      x="2"
      y="10"
      width="24"
      height="20"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M6 5.42857C6 3.53502 7.53502 2 9.42857 2H18.5714C20.465 2 22 3.53502 22 5.42857V10H6V5.42857Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <circle cx="14" cy="20" r="3" fill="currentColor" />
  </svg>
);

export { Locked };
