const Elasticsearch = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M21.8584 6C17.3965 6 13.4221 8.08734 10.8584 11.3388H29.8584L32.142 10C29.6169 7.52563 25.673 6 21.8584 6Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M8.08097 17.5C7.93474 18.3112 7.8584 19.1467 7.8584 20C7.8584 20.8533 7.93474 21.6888 8.08097 22.5H25.8584C26.3584 22.5 28.3584 22.5 28.3584 20C28.3584 18 26.6917 17.5 25.8584 17.5H8.08097Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M10.8584 28.6612C13.4221 31.9127 17.3965 34 21.8584 34C25.9237 34 29.5843 32.2672 32.142 29.5L29.8584 28.6612H10.8584Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Elasticsearch };
