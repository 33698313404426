const Log = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M10.4 23C10.4 22.2268 9.7732 21.6 9 21.6C8.2268 21.6 7.6 22.2268 7.6 23H10.4ZM9 33H7.6V34.4H9V33ZM13.5 34.4C14.2732 34.4 14.9 33.7732 14.9 33C14.9 32.2268 14.2732 31.6 13.5 31.6V34.4ZM32 28.5H33.4V27.1H32V28.5ZM10 8V6.6H8.6V8H10ZM23 17H21.6V18.4H23V17ZM23.3913 8L24.3813 7.01005L23.9712 6.6H23.3913V8ZM32 16.6087H33.4V16.0288L32.99 15.6187L32 16.6087ZM7.6 23V33H10.4V23H7.6ZM9 34.4H13.5V31.6H9V34.4ZM20.6 26.3333V29.6667H23.4V26.3333H20.6ZM17.4 29.6667V26.3333H14.6V29.6667H17.4ZM19 31.6C18.2514 31.6 17.4 30.8768 17.4 29.6667H14.6C14.6 32.1384 16.4348 34.4 19 34.4V31.6ZM20.6 29.6667C20.6 30.8768 19.7486 31.6 19 31.6V34.4C21.5652 34.4 23.4 32.1384 23.4 29.6667H20.6ZM19 24.4C19.7486 24.4 20.6 25.1232 20.6 26.3333H23.4C23.4 23.8616 21.5652 21.6 19 21.6V24.4ZM19 21.6C16.4348 21.6 14.6 23.8616 14.6 26.3333H17.4C17.4 25.1232 18.2514 24.4 19 24.4V21.6ZM27.4 29.6667V26.3333H24.6V29.6667H27.4ZM29 31.6C28.2514 31.6 27.4 30.8768 27.4 29.6667H24.6C24.6 32.1384 26.4348 34.4 29 34.4V31.6ZM30.6 30C30.6 30.5875 30.4709 30.9694 30.2984 31.1831C30.1713 31.3406 29.8686 31.6 29 31.6V34.4C30.4988 34.4 31.6961 33.9094 32.4771 32.9419C33.2128 32.0306 33.4 30.9125 33.4 30H30.6ZM29 24.4C29.6268 24.4 30.0564 24.6284 30.3281 24.8661C30.4696 24.9899 30.5607 25.1103 30.6081 25.1903C30.6318 25.2302 30.6365 25.2465 30.6324 25.2349C30.6301 25.2285 30.6232 25.2078 30.6163 25.1739C30.6098 25.1417 30.6 25.0817 30.6 25H33.4C33.4 24.5048 33.1913 24.0571 33.0169 23.7628C32.8143 23.4209 32.5304 23.0726 32.1719 22.7589C31.4436 22.1216 30.3732 21.6 29 21.6V24.4ZM29 21.6C26.4348 21.6 24.6 23.8616 24.6 26.3333H27.4C27.4 25.1232 28.2514 24.4 29 24.4V21.6ZM30.6 28.5V30H33.4V28.5H30.6ZM32 27.1H29V29.9H32V27.1ZM30.6 25V25.5H33.4V25H30.6ZM10 9.4H23V6.6H10V9.4ZM24.4 17V8H21.6V17H24.4ZM23.3913 6.6H23V9.4H23.3913V6.6ZM32 15.6H23V18.4H32V15.6ZM22.4014 8.98995L31.01 17.5986L32.99 15.6187L24.3813 7.01005L22.4014 8.98995ZM33.4 17V16.6087H30.6V17H33.4ZM11.4 19.5V8H8.6V19.5H11.4ZM30.6 17V20H33.4V17H30.6Z"
      fill="currentColor"
    />
  </svg>
);

export { Log };
