const Admin = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={className}
    >
      <path
        d="M27 22L28.6458 24.7348L31.7553 25.4549L29.663 27.8652L29.9389 31.0451L27 29.8L24.0611 31.0451L24.337 27.8652L22.2447 25.4549L25.3542 24.7348L27 22Z"
        fill="currentColor"
      />
      <path
        d="M20.7035 29.6961C19.2914 30.1664 17.7807 30.4211 16.2105 30.4211C8.36227 30.4211 2 24.0588 2 16.2105C2 8.36227 8.36227 2 16.2105 2C24.0588 2 30.4211 8.36227 30.4211 16.2105C30.4211 17.7524 30.1755 19.2368 29.7214 20.627"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <circle
        cx="5"
        cy="5"
        r="5"
        transform="matrix(1 0 0 -1 11 18)"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path d="M6 27L10.7929 22H21" stroke="currentColor" strokeWidth="2.8" />
    </svg>
  );
};

export { Admin };
