const Login = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M21 20L7 20M20 33H33V7L20 7M24 20L21 17V23L24 20Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
  </svg>
);

export { Login };
