import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLockBodyScroll } from 'src/hooks/useLockBodyScroll';

import s from './PortalModal.module.scss';

function BannerCloseIcon({ onClick, ...rest }: { onClick: () => void }) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="currentColor"
      className="--modal-close gtm-click-modal-close"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      {...rest}
    >
      <path d="M36.5999 11.4199C35.8199 10.6399 34.5599 10.6399 33.7799 11.4199L23.9999 21.1799L14.2199 11.3999C13.4399 10.6199 12.1799 10.6199 11.3999 11.3999C10.6199 12.1799 10.6199 13.4399 11.3999 14.2199L21.1799 23.9999L11.3999 33.7799C10.6199 34.5599 10.6199 35.8199 11.3999 36.5999C12.1799 37.3799 13.4399 37.3799 14.2199 36.5999L23.9999 26.8199L33.7799 36.5999C34.5599 37.3799 35.8199 37.3799 36.5999 36.5999C37.3799 35.8199 37.3799 34.5599 36.5999 33.7799L26.8199 23.9999L36.5999 14.2199C37.3599 13.4599 37.3599 12.1799 36.5999 11.4199V11.4199Z" />
    </svg>
  );
}

export function PortalModal({
  children,
  onClickForClose,
  className = '',
  portalClassName = '',
  overlayClassName = '',
  withoutDefaultCloseIcon,
  closeIcon,
  ...rest
}: {
  children: React.ReactNode;
  onClickForClose: () => void;
  className?: string;
  withoutDefaultCloseIcon?: boolean;
  closeIcon?: React.ReactNode;
  overlayClassName?: string;
  portalClassName?: string;
}) {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  useEffect(() => {
    const onEscapePressed = (event: KeyboardEvent) => {
      if (event.key === 'Escape') onClickForClose();
    };

    document.addEventListener('keydown', onEscapePressed);

    return () => {
      document.removeEventListener('keydown', onEscapePressed);
    };
  }, [onClickForClose]);

  useLockBodyScroll();

  const contentWrapper = (
    <div className={cn(s.root, portalClassName)}>
      <div
        className={cn(s.overlay, overlayClassName)}
        onClick={onClickForClose}
      />
      {closeIcon ? closeIcon : ''}
      <div className={className}>
        {!withoutDefaultCloseIcon && (
          <BannerCloseIcon onClick={onClickForClose} {...rest} />
        )}
        {children}
      </div>
    </div>
  );

  if (isBrowser) {
    const container = document.getElementById('portal') as Element;
    return createPortal(contentWrapper, container);
  }

  return <div className="c-portal-modal_hidden" />;
}
