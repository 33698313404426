const PhysicalStorage = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M4.4375 15.1995L2 11.9495V4.79946L4.4375 2.19946H25.5625L28 4.79946V11.9495L25.5625 15.1995"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path
      d="M28 18.4495L25.5625 15.1995H4.4375L2 18.4495V25.5995L4.4375 28.1995H25.5625L28 25.5995V18.4495Z"
      stroke="currentColor"
      strokeWidth="2.8"
    />
    <path d="M23 5.69946L23 11.6995" stroke="currentColor" strokeWidth="2.8" />
    <path d="M23 18.6995L23 24.6995" stroke="currentColor" strokeWidth="2.8" />
    <path d="M12 5.69946L12 11.6995" stroke="currentColor" strokeWidth="2.8" />
    <path d="M12 18.6995L12 24.6995" stroke="currentColor" strokeWidth="2.8" />
    <path d="M18 5.69946L18 11.6995" stroke="currentColor" strokeWidth="2.8" />
    <path d="M18 18.6995L18 24.6995" stroke="currentColor" strokeWidth="2.8" />
    <path d="M7 5.69946L7 11.6995" stroke="currentColor" strokeWidth="2.8" />
    <path d="M7 18.6995L7 24.6995" stroke="currentColor" strokeWidth="2.8" />
  </svg>
);

export { PhysicalStorage };
