const Database = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <ellipse
        cx="15"
        cy="8.5"
        rx="13"
        ry="6.5"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M28 15C28 18.5899 22.1797 21.5 15 21.5C7.8203 21.5 2 18.5899 2 15"
        stroke="currentColor"
        strokeWidth="2.8"
      />
      <path
        d="M28 8.5V21.5C28 25.0899 22.1797 28 15 28C7.8203 28 2 25.0899 2 21.5V8.5"
        stroke="currentColor"
        strokeWidth="2.8"
      />
    </svg>
  );
};

export { Database };
